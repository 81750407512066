import React, {
  useContext,
  useState,
  useEffect,
  useReducer
} from 'react';
import {
  arrayOf,
  node,
  oneOfType,
  string,
  shape as shapeProp
} from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

import { useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';

import { triggerColorFamilyAnalytics } from '../utils/analyticsUtils';
import compareReducer from '../components/Compare/compareReducer';
import {
  getActiveBrand,
  getBreakpoint,
  getChannel,
  updateTitle
} from '../utils/utils';
import rAFPolyfill from '../utils/rAF';
import { ColorDetailsContext, ColorFamilyContext, CompareContext, LayoutContext } from './Contexts';
import { constructURL, updateURL, getUrlData } from '../utils/urlUtils';
import { Labels } from '../constants/Constants';
import { dataModel } from '../dataModel';

export const ColorWallProvider = ({ children, ssrData }) => {
  let urlObject = typeof window === 'undefined' ? ssrData : window.location;
  const urlData = getUrlData(urlObject) || {};

  const { channel: expChannel } = useContext(ExperienceContext);
  const { storeId, storeZip } = useStore();

  const [channel, setChannel] = useState(expChannel?.toUpperCase());
  const [shouldDisplayTopColors, setShouldDisplayTopColors] = useState(urlData.color ? urlData.color !== 'popular' : false);
  const [currentColorFamily, setCurrentColorFamily] = useState(urlData.color || 'popular');
  const [colorFamilyBrand, setColorFamilyBrand] = useState(urlData.brand || null);
  const [colorDetailBrand, setColorDetailBrand] = useState(urlData.brandName || null);
  const [colorNumber, setColorNumber] = useState(urlData.colorNumber || null);
  const [navBarIndex, setNavBarIndex] = useState(urlData.navBarIndex || null);
  const [activeColor, setActiveColor] = useState({});
  const [paintIdsMissingSamples, setPaintIdsMissingSamples] = useState([]);

  const [compareState, dispatchCompare] = useReducer(compareReducer, {
    colorsToCompare: []
  });

  const { data: colorFamilyData, loading: colorFamilyLoading, error: colorFamilyError } = useDataModel('paint', {
    variables: {
      colorfamily: currentColorFamily,
      channel,
      navbarindex: navBarIndex,
      vendor: colorFamilyBrand
    },
    ssr: true
  });

  const { data: colorDetailsData, loading: colorDetailsLoading, error: colorDetailsError } = useDataModel('paintColorDetails', {
    variables: {
      colornumber: colorNumber,
      vendor: colorDetailBrand,
      channel: 'DESKTOP' // DON'T CHANGE - Details calls are always done on Desktop
    },
    skip: !colorNumber || !colorDetailBrand,
    ssr: true
  });

  const paintSample = colorDetailsData?.paintColorDetails?.sample;
  const { data: productDetails, loading: productDetailsLoading, error: productDetailsError } = useDataModel('product', {
    variables: {
      itemId: paintSample?.itemId,
      storeId,
      storeZip,
    },
    skip: !paintSample,
    ssr: false
  });

  const activeBrand = getActiveBrand((colorFamilyData?.paint?.dimensions)
    ? colorFamilyData.paint.dimensions
    : []);

  const selectedNavBars = (
    ((colorFamilyData?.paint?.navBars)
      ? colorFamilyData.paint.navBars
      : []
    ).filter(({ selected }) => selected)[0] || {});

  const onWindowResize = () => {
    const newChannel = getChannel(getBreakpoint());
    if (channel !== newChannel) {
      setChannel(newChannel);
    }
  };

  useEffect(() => {
    triggerColorFamilyAnalytics(currentColorFamily);
  }, [currentColorFamily]);

  useEffect(() => {
    const ssr = typeof window === 'undefined';
    if (!ssr) {
      rAFPolyfill();
      /*
        Opera Mini is the only "living" current browser that doesn't allow this yet:
        https://caniuse.com/?search=smoothscroll
      */
      smoothscroll.polyfill();
    }
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  useEffect(() => {
    const { label: brand } = activeBrand;

    if (typeof window !== 'undefined') {
      updateURL(constructURL({
        urlObject,
        colorFamily: currentColorFamily,
        navBarIndex,
        brand,
        colorNumber,
        colorBrand: colorDetailBrand
      }));
      if (colorFamilyData?.paint?.dimensions) {
        updateTitle({ colorFamily: currentColorFamily, dimensions: colorFamilyData.paint.dimensions });
      }
    }
  });

  useEffect(() => {
    const colorFamilyName = Labels.colorFamilies[currentColorFamily];
    const isPopularColorFamily = colorFamilyName === Labels.colorFamilies.popular;
    if (isPopularColorFamily && shouldDisplayTopColors) {
      setShouldDisplayTopColors(false);
      setNavBarIndex(null);
    } else if (!isPopularColorFamily && !shouldDisplayTopColors) {
      setShouldDisplayTopColors(true);
      setNavBarIndex('00');
    }
  }, [currentColorFamily]);

  return (
    <ColorFamilyContext.Provider value={{
      currentColorFamily,
      setCurrentColorFamily,
      colorFamilyBrand,
      setColorFamilyBrand,
      navBarIndex,
      setNavBarIndex,
      activeColor,
      setActiveColor,
      colorFamilyData,
      colorFamilyLoading,
      colorFamilyError,
      activeBrand,
      selectedNavBars
    }}
    >
      <ColorDetailsContext.Provider value={{
        colorNumber,
        setColorNumber,
        colorDetailBrand,
        setColorDetailBrand,
        colorDetailsData,
        colorDetailsLoading,
        colorDetailsError,
        paintIdsMissingSamples,
        setPaintIdsMissingSamples,
        productDetails,
        productDetailsLoading,
        productDetailsError
      }}
      >
        <CompareContext.Provider value={{
          compareState,
          dispatchCompare
        }}
        >
          <LayoutContext.Provider value={{
            channel,
            setChannel,
            shouldDisplayTopColors,
            setShouldDisplayTopColors
          }}
          >
            {children}
          </LayoutContext.Provider>
        </CompareContext.Provider>
      </ColorDetailsContext.Provider>
    </ColorFamilyContext.Provider>
  );
};

ColorWallProvider.dataModel = dataModel;

ColorWallProvider.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  ssrData: shapeProp({
    pathname: string,
    search: string
  })
};

ColorWallProvider.defaultProps = {
  ssrData: {}
};