// Channel specific constants
export const BREAKPOINTS = [320, 576, 768, 992, 1200, 1440];
export const DESKTOP = 'DESKTOP';
export const MOBILE = 'MOBILE';
export const TABLET = 'TABLET';

// Url building constants
export const HOME_DEPOT_HOSTNAME = 'https://www.homedepot.com';
const STATIC_ASSETS_HOSTNAME = 'https://assets.thdstatic.com/images/v1';
export const HOME_DEPOT_STATIC_HOSTNAME = `${STATIC_ASSETS_HOSTNAME}/colorwall`;
export const GCP_COLOR_FAMILY_PATH = '/color-family';
export const GCP_BRAND_LOGO_PATH = '/brand-logo';
export const GCP_LIFESTYLE_PATH = '/color-details';
export const GCP_RELATED_ITEMS_PATH = '/related-items';
export const NCNI_QUERY_PARAM = '&NCNI-5';
export const SVG_FILE_EXTENSION = '.svg';
export const PNG_FILE_EXTENSION = '.png';
export const JPG_FILE_EXTENSION = '.jpg';

// Component specific constants
export const LIFESTYLE_IMAGES = [
  'bedroom-one',
  'bedroom-two',
  'bedroom-three',
  'bathroom-one',
  'bathroom-two',
  'kitchen-one',
  'kitchen-two',
  'living-room-one',
  'living-room-two',
  'dining-room',
  'front-door-one',
  'front-door-two',
  'exterior-one',
  'exterior-two'
];

export const Labels = Object.freeze({
  colorFamilies: {
    popular: 'Popular',
    neutral: 'Neutral',
    white: 'White',
    grey: 'Gray',
    black: 'Black',
    brown: 'Brown',
    red: 'Red',
    orange: 'Orange',
    yellow: 'Yellow',
    green: 'Green',
    blue: 'Blue',
    purple: 'Purple',
  },
  seeAll: (colorFamily) => `See All ${colorFamily || ''} Paint Colors `
});

export const COLOR_FAMILIES = [
  { name: 'Popular', key: 'popular', imgSrc: '' },
  { name: 'White', key: 'white', imgSrc: '' },
  { name: 'Gray', key: 'gray', imgSrc: '' },
  { name: 'Black', key: 'black', imgSrc: '' },
  { name: 'Brown', key: 'brown', imgSrc: '' },
  { name: 'Neutral', key: 'neutral', imgSrc: '' },
  { name: 'Red', key: 'red', imgSrc: '' },
  { name: 'Orange', key: 'orange', imgSrc: '' },
  { name: 'Yellow', key: 'yellow', imgSrc: '' },
  { name: 'Green', key: 'green', imgSrc: '' },
  { name: 'Blue', key: 'blue', imgSrc: '' },
  { name: 'Purple', key: 'purple', imgSrc: '' },
];
export const STEP_1 = 'Step 1';
export const STEP_2 = 'Step 2';
export const STEP_3 = 'Step 3';

// Color constants
export const BLACK = 'black';
export const WHITE = 'white';

// Roomvo Script url
export const ROOMVO_SCRIPT_SRC = 'https://cdn.roomvo.com/static/scripts/b2b/homedepot.js';