import { NCNI_QUERY_PARAM } from '../constants/Constants';

const parseLocation = (pathname = '') => {
  return {
    isRenderNucleus: pathname.match(/([rR]ender-[nN]ucleus)/)?.index > -1,
    isHarmony: pathname.match(/chapters\/overview/)?.index > -1
  };
};

export const constructURL = ({
  urlObject, colorFamily, navBarIndex, brand, colorBrand, colorNumber
}) => {
  const appPath = 'b';
  const paintIdeasPath = 'Paint-Ideas';
  const colorBrandPath = brand ? `/${brand.replace(/\s/g, '')}` : '';
  const navBarPath = navBarIndex ? `/${navBarIndex}` : '';
  // Search Params
  const colorPath = colorNumber ? `colorNumber=${colorNumber}${NCNI_QUERY_PARAM}` : '';
  const brandFilterPath = colorBrand ? `brand=${colorBrand}` : '';
  const urlParams = new URLSearchParams(urlObject?.search || '');
  const debug = urlParams.get('debug');
  const debugPath = debug ? `debug=${debug}` : '';
  const disableSSR = urlParams.get('disableSSR');
  const disableSSRPath = disableSSR ? `disableSSR=${disableSSR}` : '';
  const experience = urlParams.get('experience');
  const experiencePath = experience ? `experience=${experience}` : '';
  const expVersion = urlParams.get('expversion');
  const expVersionPath = expVersion ? `expversion=${expVersion}` : '';
  const { isRenderNucleus, isHarmony } = parseLocation(urlObject?.pathname || '');
  const searchParamUrls = [colorPath, brandFilterPath, debugPath, disableSSRPath, experiencePath, expVersionPath];
  const existingSearchParamUrls = searchParamUrls.filter((url) => !!url);
  const searchParamString = existingSearchParamUrls.length > 0 ? `?${existingSearchParamUrls.join('&')}` : '';
  // eslint-disable-next-line max-len
  const newUrl = `/${appPath}/${paintIdeasPath}/${colorFamily}${navBarPath}${colorBrandPath}${searchParamString}`;
  let urlPrefix = '';
  if (isRenderNucleus) {
    urlPrefix = '/render-nucleus';
  } else if (isHarmony) {
    urlPrefix += '/chapters/overview';
  }
  return `${urlPrefix}${newUrl}`;
};

export const updateURL = (url) => {
  if (typeof window !== 'undefined' && window.history.replaceState) {
    window.history.replaceState(null, null, url);
  }
};

export const getUrlData = (urlObject) => {
  const { pathname = '', search = '' } = urlObject;

  let splitPathname = pathname.split('/');
  let colorWallUrlParamsStartIndex = splitPathname.findIndex((item) => item.toLowerCase() === 'paint-ideas') + 1;
  let color = (splitPathname[colorWallUrlParamsStartIndex] || 'popular').toLowerCase();
  let navBarIndex = null;
  let brand = null;
  if (splitPathname[colorWallUrlParamsStartIndex + 1] === 'Behr' || splitPathname[colorWallUrlParamsStartIndex + 1] === 'PPG%20-%20Glidden' || splitPathname[colorWallUrlParamsStartIndex + 1] === 'PPG-Glidden') {
    brand = splitPathname?.[colorWallUrlParamsStartIndex + 1];
  } else if (splitPathname[colorWallUrlParamsStartIndex + 1]?.startsWith('0')) {
    navBarIndex = splitPathname?.[colorWallUrlParamsStartIndex + 1] || null;
    if (splitPathname[colorWallUrlParamsStartIndex + 2] === 'Behr' || splitPathname[colorWallUrlParamsStartIndex + 1] === 'PPG%20-%20Glidden' || splitPathname[colorWallUrlParamsStartIndex + 1] === 'PPG-Glidden') {
      brand = splitPathname?.[colorWallUrlParamsStartIndex + 2] || null;
    }
  }

  if (brand) {
    brand = decodeURI(brand);
  }

  // Search Params parsing
  const urlParams = new URLSearchParams(search);
  const colorNumber = urlParams.get('colorNumber');
  const brandName = decodeURI(urlParams.get('brand')) !== 'null' ? decodeURI(urlParams.get('brand')) : null;

  return {
    color,
    navBarIndex,
    brand,
    colorNumber,
    brandName
  };
};