import React from 'react';
import classNames from 'classnames/bind';
import { bool, oneOfType, array, string } from 'prop-types';

import styles from './SeoCopyBlock.module.scss';

const cx = classNames.bind(styles);

const SeoBoldText = ({ children, isBlock }) => {
  const Element = isBlock ? 'p' : 'span';

  return (
    <Element className={cx('seoCopyBlockBoldText')}>
      {children}
    </Element>
  );
};

SeoBoldText.propTypes = {
  isBlock: bool,
  children: oneOfType([array, string]).isRequired
};

SeoBoldText.defaultProps = {
  isBlock: false
};

export default SeoBoldText;
