import React from 'react';
import { Loader } from '@thd-olt-component-react/core-ui';

import { Portal } from './Portal/Portal';

// TODO: prevent container scrolling
const LoadingOverlay = () => (
  <Portal selector="body">
    {React.cloneElement(
      <div>
        <Loader active />
      </div>,
      {
        className: 'sui-bg-strong sui-opacity-70 sui-flex sui-items-center sui-justify-center sui-h-full sui-w-full'
          + ' sui-z-10 sui-top-0 sui-left-0 sui-fixed sui-text-xl'
      })}
  </Portal>
);

export default LoadingOverlay;
