import React from 'react';
import { shape, string } from 'prop-types';

import { QueryProvider } from '@thd-nucleus/data-sources';

import { ColorWallProvider } from './ColorWallProvider';
import { ColorWall } from '../components/ColorWall/ColorWall';

const App = ({ ssrData }) => {
  return (
    <QueryProvider cacheKey="color-wall">
      <ColorWallProvider ssrData={ssrData}>
        <ColorWall />
      </ColorWallProvider>
    </QueryProvider>
  );
};

App.displayName = 'ColorWall';

App.propTypes = {
  ssrData: shape({
    pathname: string,
    search: string
  })
};

App.defaultProps = {
  ssrData: {}
};

export { App };