import {
  GCP_RELATED_ITEMS_PATH,
  HOME_DEPOT_STATIC_HOSTNAME,
  JPG_FILE_EXTENSION,
} from '../../constants/Constants';

const itemsData = [{
  imageName: 'spray-paint',
  title: 'Spray Paint',
  link: '/b/Paint-Spray-Paint/N-5yc1vZapz5'
}, {
  imageName: 'garage-floor-stains',
  title: 'Garage Floor Coatings',
  link: '/b/Paint-Garage-Floor-Paint/N-5yc1vZbd13'
}, {
  imageName: 'patio-paint',
  title: 'Porch & Patio Paint',
  link: '/b/Paint-Exterior-Paint-Concrete-Porch-Patio-Paint/N-5yc1vZc6ol'
}, {
  imageName: 'rollers',
  title: 'Rollers',
  link: '/b/Paint-Paint-Tools-Supplies-Paint-Rollers/N-5yc1vZaqpy'
}, {
  imageName: 'paint-tray',
  title: 'Trays',
  link: '/b/Paint-Paint-Tools-Supplies-Paint-Buckets-Trays-Paint-Trays/N-5yc1vZaqcd'
}, {
  imageName: 'paint-brush',
  title: 'Paint Brushes ',
  link: '/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories/N-5yc1vZbd1o'
}, {
  imageName: 'paint-bucket',
  title: 'Buckets',
  link: '/b/Paint-Paint-Tools-Supplies-Paint-Buckets-Trays-Paint-Buckets-Lids/N-5yc1vZc5bk'
}, {
  imageName: 'plastic-sheeting',
  title: 'Tarps, Sheeting, & Plastic Lining',
  link: '/b/Paint-Paint-Tools-Supplies-Tarps-Drop-Cloths-Plastic-Sheeting-Tarps/N-5yc1vZci18'
}, {
  imageName: 'spackling',
  title: 'Patching & Repair',
  link: '/b/Paint-Paint-Supplies-Patching-Repair/N-5yc1vZ2fkpd23'
}, {
  imageName: 'stains',
  title: 'Interior Stains',
  link: '/b/Paint-Interior-Wood-Stains/N-5yc1vZbo8p'
}, {
  imageName: 'exterior-stains',
  title: 'Exterior Stains',
  link: '/b/Paint-Exterior-Stain-Sealers/N-5yc1vZbbbr'
}, {
  imageName: 'primer',
  title: 'Primers',
  link: '/b/Paint-Primers/N-5yc1vZbt0t'
}];

export const buildItems = () => {
  return itemsData.map(({ title, imageName, link }) => {
    return {
      title,
      link,
      src: `${HOME_DEPOT_STATIC_HOSTNAME}${GCP_RELATED_ITEMS_PATH}/${imageName}${JPG_FILE_EXTENSION}`
    };
  });
};
