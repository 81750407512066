import {
  ADD_COLOR_TO_COMPARE,
  REMOVE_COLOR_FROM_COMPARE,
  REMOVE_ALL_COLORS_FROM_COMPARE
} from './compareActions';

const compareReducer = (
  state = {
    colorsToCompare: []
  },
  { type, payload }
) => {
  switch (type) {
  case ADD_COLOR_TO_COMPARE: {
    return {
      ...state,
      colorsToCompare: [...state.colorsToCompare, payload],
    };
  }
  case REMOVE_COLOR_FROM_COMPARE: {
    return {
      ...state,
      colorsToCompare: state.colorsToCompare.filter((color) => {
        return (
          color.colorName !== payload.colorName
              && color.colorNumber !== payload.colorNumber
              && color.rgb !== payload.rgb
        );
      }),
    };
  }
  case REMOVE_ALL_COLORS_FROM_COMPARE: {
    return {
      ...state,
      colorsToCompare: [],
    };
  }
  default: {
    return state;
  }
  }
};

export default compareReducer;
