import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Orange Paint',
  introduction: <p>Spice up your bedroom, kitchen, or dining room with eye-catching, vibrant orange paint. Find out which common shades of orange could work well in your home.</p>,
  listTitle: 'Shades of Orange Paint',
  listIntroduction: 'Whether you’re looking to promote calmness or energy in your space, there are plenty shades of orange paint that can set your desired mood. Here are some popular shades to consider:',
  shadesList: [
    {
      title: 'Burnt Orange',
      copy: 'Burnt orange is a medium dark orange that’s often used in traditional or rustic décors, but it can be incorporated in modern designs as well. If you’re interested in having orange kitchen walls, burnt orange paint makes a great choice because it helps add a warm and comforting feel to the space. When used as an accent, burnt orange works well with dark blues and grays. Pair burnt orange with mint green and peach to create a brighter palette.'
    }, {
      title: 'Terra Cotta',
      copy: 'Terracotta is a mixture between orange and brown that resembles the earthy color of fired clay. Terracotta paint colors can be lighter or darker depending on the other colors that are mixed in. Dark shades look great in Southwestern-inspired décor, especially when paired with turquoise accents. In dining rooms or kitchens, try a light shade of terracotta complemented with off-white trim.'
    }, {
      title: 'Coral',
      copy: 'Coral is a pinkish orange color that brings a fresh vibrancy into any space. Whether you use coral as an accent or dominant color, this dynamic hue particularly pops when combined with neutrals such as beige, gray, and white. If you want to accent coral with a non-neutral color, try blue for a cooler tone or yellow for a warmer feel.'
    }
  ],
  tipsTitle: 'Tips on Painting Tools',
  tips: [
    <>To ensure your paint project goes smoothly, it’s important to have the appropriate <SeoLink href="/b/Paint-Paint-Tools-Supplies/N-5yc1vZci1w">painting tools</SeoLink> for the job.</>
  ],
  toolsTips: [
    <>
      <BoldText>Brushes:</BoldText>
      {' '}
      When looking for a
      {' '}
      <SeoLink
        href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac"
      >
        paint brush
      </SeoLink>
      , make sure you pick the right style. Flat brushes are ideal for painting large surfaces
      {' '}
      while angle brushes are recommended for painting trim. Thin angle brushes are great for
      {' '}
      painting corners and edges.
    </>,
    <>
      <BoldText>Tapes:</BoldText>
      {' '}
      <SeoLink
        href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu"
      >
        Painter’s tape
      </SeoLink>
      {' '}
      is a must-have for any paint job. Be sure to get painter’s tape instead of masking tape,
      {' '}
      which leaves a sticky residue and can cause paint to peel.
    </>
  ],
  closing: 'Whether you’re interested in orange bedrooms, living rooms, or dining rooms, there are plenty of orange shades to choose from to give your home a warm, contemporary flair.'
};
