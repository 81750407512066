import ReactDOM from 'react-dom';
import { oneOfType, arrayOf, node, string } from 'prop-types';
import { isBrowser } from '../../../utils/utils';

export const Portal = ({ children, selector }) => {
  return (isBrowser()
    ? ReactDOM.createPortal(
      children,
      document.querySelector(selector)
    ) : null
  );
};

Portal.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  selector: string.isRequired
};

export default Portal;
