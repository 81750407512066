import React from 'react';
import { useLocation } from '@thd-olt-component-react/router';
import { Metadata, ColorWallMetadata } from '@thd-olt-component-react/metadata';
import { ColorWall, getUrlData } from '@thd-olt-component-react/color-wall';
import { shape, string } from 'prop-types';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export const ColorWallContainer = ({ data }) => {
  const { pathname, querystring } = useLocation();
  const { brand, color: colorFamily } = getUrlData(typeof window === 'undefined' ? data : window.location);

  return (
    <>
      <ErrorBoundary name="color-wall-metadata">
        <Metadata>
          <ColorWallMetadata
            data={{
              colorFamily,
              brand,
              colorWallData: {
                colorFamily,
                colorWallData: {
                  dimensions: [
                    {
                      refinements: [
                        {
                          label: brand,
                          selected: true
                        }
                      ]
                    }
                  ]
                }
              }
            }}
            pathname={pathname}
            querystring={querystring}
          />
        </Metadata>
      </ErrorBoundary>
      <ErrorBoundary name="color-wall">
        <div className="grid isBound">
          <ColorWall ssrData={data} />
        </div>
      </ErrorBoundary>
    </>
  );
};

ColorWallContainer.propTypes = {
  data: shape({
    pathname: string,
    search: string
  })
};

ColorWallContainer.defaultProps = {
  data: {}
};