import React from 'react';
import classNames from 'classnames/bind';
import { bannerMessages, brandLogos } from './bannerData';

import styles from './ValueMessagingBanner.module.scss';

const cx = classNames.bind(styles);

const ValueMessagingBanner = () => (
  <div className={cx('banner-container', 'sui-w-full')}>
    <div className={cx('banner-messages')}>
      {bannerMessages.map((message) => (
        <section key={message} className={cx('banner-message')}>
          <h4 className={cx('top-message')}>{message}</h4>
          <h4 className={cx('bottom-message')}>GUARANTEED</h4>
        </section>
      ))}
    </div>
    <div className={cx('banner-logos')}>
      {brandLogos}
    </div>
  </div>
);

export default ValueMessagingBanner;
