import {
  LIFESTYLE_IMAGES,
  PNG_FILE_EXTENSION,
  SVG_FILE_EXTENSION,
  HOME_DEPOT_STATIC_HOSTNAME,
  GCP_LIFESTYLE_PATH,
  GCP_BRAND_LOGO_PATH,
  WHITE,
  BLACK
} from '../constants/Constants';

import { getContentColor } from './utils';

export const getBrandLogoImageUrl = (colorBrand, rgb, contentColor) => {
  let brandsArray = colorBrand.split('-');
  return brandsArray.map((brand) => {
    if (contentColor === WHITE || contentColor === BLACK) {
      return `${HOME_DEPOT_STATIC_HOSTNAME}${GCP_BRAND_LOGO_PATH}/${brand.trim()}-${contentColor}${SVG_FILE_EXTENSION}`;
    }
    return `${HOME_DEPOT_STATIC_HOSTNAME}${GCP_BRAND_LOGO_PATH}/${brand.trim()}-${getContentColor(
      rgb
    )}${SVG_FILE_EXTENSION}`;
  });
};

const buildLifestyleImageUrl = (lifestyleImage, channel = 'mobile') => {
  // eslint-disable-next-line max-len
  const unprocessedImageUrl = `${HOME_DEPOT_STATIC_HOSTNAME}${GCP_LIFESTYLE_PATH}/${channel.toLowerCase()}/${lifestyleImage}${PNG_FILE_EXTENSION}`;
  return unprocessedImageUrl;
};

export const getLifeStyleImages = (channel) => {
  return (
    LIFESTYLE_IMAGES.map((lifestyleImage) => {
      return buildLifestyleImageUrl(lifestyleImage, channel);
    }) || []
  );
};