import React, { useState, useContext } from 'react';
import classNames from 'classnames/bind';

import { Tab, Tabs } from '@thd-olt-component-react/core-ui';

import { CompareContext, LayoutContext } from '../../../../context/Contexts';
import styles from './CompareSideBySide.module.scss';
import { CompareColorCard } from './CompareColorCard/CompareColorCard';

const cx = classNames.bind(styles);

const roomTypes = [
  'Kitchen',
  'Bedroom',
  'Living Room',
  'Dining Room',
  'Bathroom',
  'Exterior',
];

export const CompareSideBySide = () => {
  const { channel } = useContext(LayoutContext);
  const { compareState } = useContext(CompareContext);
  let { colorsToCompare } = compareState;

  const [tabIndex, setTabIndex] = useState(0);

  if (channel === 'MOBILE') {
    colorsToCompare = colorsToCompare.slice(0, 4);
  }
  return (
    <div className={cx('compareSideBySideWrapper')}>
      <Tabs
        gridStyle
        onChange={({ index }) => setTabIndex(index)}
        value={tabIndex}
      >
        {
          roomTypes.map((roomType, index) => (
            <Tab
              key={index}
              label={roomType}
            />
          ))
        }
      </Tabs>
      <div className={cx('colorCards')}>
        {colorsToCompare.map((color, index) => {
          const sideClasses = cx({
            left: (index % 2 === 0),
            right: (index % 2 === 1)
          });
          return (
            <div key={color.colorName} className={sideClasses}>
              <CompareColorCard
                colorInfo={color}
                selectedRoomName={roomTypes[tabIndex]}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};