export const pushAnalyticsEvent = (analyticsObj) => {
  if (window.digitalData && window.digitalData.event) {
    window.digitalData.event.push(analyticsObj);
  }
};

export const triggerColorFamilyAnalytics = (colorFamily) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('color-wall.ready', { colorFamily });
};

export const thdAnalyticsEventObj = {
  category: {
    primaryCategory: 'N/A',
  },
  eventInfo: {
    eventName: 'N/A',
  },
  colorExplore: {
    color: 'N/A',
    colorID: 'N/A',
    colorFamily: 'N/A',
    colorHue: 'N/A',
    colorAnchor: 'N/A',
    cem: 'N/A',
    pageType: 'N/A',
    searchFilter: 'N/A',
    searchPosition: 'N/A',
  },
};

export const buildBuySampleAnalyticsObj = (itemId, section) => (
  {
    category: {
      primaryCategory: 'stash'
    },
    eventInfo: {
      eventName: 'cart addition info'
    },
    eventMode: 'stash',
    stash: {
      component: 'colors',
      item: [
        { displayPosition: 'n/a' },
        { productInfo: { sku: itemId } }
      ],
      pageType: 'content',
      section,
      sharedSection: 'sample',
      target: 'atc'
    }
  }
);

export const buildAccordionAnalyticsObj = (data) => {
  const {
    colorName,
    colorFamily,
    navBarIndex,
    colorNumber,
    selectedBrandFilter,
    colorFilter
  } = data;

  return {
    category: { primaryCategory: 'overlay' },
    colorExplore: {
      cem: 'browse',
      color: colorName,
      colorFamily,
      colorHue: navBarIndex,
      colorID: colorNumber,
      pageType: 'overlay',
      brandFilter: selectedBrandFilter,
      colorFilter
    },
    eventInfo: { eventName: 'paint details' }
  };
};