import {
  useHelmet,
  TitleTag,
  MetaTag,
  LinkTag
} from '@thd-nucleus/thd-helmet';

const colorWallHandler = ({ props }) => {
  const { pathname, data } = props;
  const { colorWallData } = data || {};
  const { colorFamily, colorWallData: colorWallDataInner } = colorWallData || {};
  const { dimensions } = colorWallDataInner || {};
  const brand = dimensions?.[0]?.refinements
    .find((refinement) => refinement.selected)
    ?.label || null;

  const type = 'Category';
  const formatColorFamily = colorFamily ? colorFamily.charAt(0).toUpperCase() + colorFamily.slice(1) : 'Popular';
  const title = `${formatColorFamily} Paint Colors ${brand ? `from ${brand} ` : ''}- The Home Depot`;
  const canonical = `https://www.homedepot.com${pathname}`;
  const tags = [
    new TitleTag(title),
    new LinkTag({ rel: 'canonical', href: canonical, id: 'canonical' }),
    new MetaTag({ property: 'og:title', content: title, id: 'ogTitle' }),
    new MetaTag({ property: 'og:url', content: canonical, id: 'ogUrl' }),
    new MetaTag({ property: 'og:type', content: type, id: 'ogType' }),
    new MetaTag({ property: 'og:site_name', content: 'The Home Depot', id: 'ogSiteName' }),
    new MetaTag({ property: 'fb:admins', content: '100005416960152', id: 'fbAdmins' })
  ];

  return tags;
};

const ColorWallMetadata = (props) => {
  useHelmet('colorWall', { props }, colorWallHandler);
  return null;
};

ColorWallMetadata.displayName = 'ColorWallMetadata';

export { ColorWallMetadata };