import React, { useEffect, useRef, useContext } from 'react';
import classNames from 'classnames/bind';

import {
  COLOR_FAMILIES,
  STEP_1,
  HOME_DEPOT_STATIC_HOSTNAME,
  GCP_COLOR_FAMILY_PATH,
  SVG_FILE_EXTENSION
} from '../../constants/Constants';

import { Title } from '../Title/Title';
import styles from './ColorFamilies.module.scss';
import { logError } from '../../utils/utils';

import { ColorDetailsContext, ColorFamilyContext } from '../../context/Contexts';

const cx = classNames.bind(styles);
const SELECTED_FAMILY_ID = 'SELECTED_FAMILY';

export const ColorFamilies = () => {
  const { currentColorFamily, setCurrentColorFamily, setNavBarIndex } = useContext(ColorFamilyContext);
  const { setColorNumber, setColorDetailBrand } = useContext(ColorDetailsContext);
  const colorFamilyData = COLOR_FAMILIES;
  const colorFamiliesRef = useRef(null);

  const buildColorFamilyImgUrl = (key) => {
    if (key === 'gray') {
      return `${HOME_DEPOT_STATIC_HOSTNAME}${GCP_COLOR_FAMILY_PATH}/families/family-grey${SVG_FILE_EXTENSION}`;
    }

    return `${HOME_DEPOT_STATIC_HOSTNAME}${GCP_COLOR_FAMILY_PATH}/families/family-${key}${SVG_FILE_EXTENSION}`;
  };

  const scrollToSelectedFamily = (selectedItem) => {
    colorFamiliesRef.current.scrollLeft = (
      selectedItem.offsetLeft - (colorFamiliesRef.current.clientWidth / 2)
    ) + (selectedItem.offsetWidth / 2);
  };

  useEffect(() => {
    try {
      const selectedItem = colorFamiliesRef.current.querySelector('#' + SELECTED_FAMILY_ID);
      scrollToSelectedFamily(selectedItem);
    } catch (errors) {
      logError(errors);
    }
  }, []);

  return (
    <div>
      <section ref={colorFamiliesRef} className={cx('colorFamiliesWrapper')}>
        <Title step={STEP_1} title="Select a color family" className={cx('filtersTitle')} />
        <div className="sui-overflow-y-hidden sui-overflow-x-auto">
          <div className={cx('colorFamilies')} data-bugbear-id="colorFamiliesContainer">
            {colorFamilyData.map(({ name, key }) => {
              const isColorFamilySelected = key === currentColorFamily;
              return (
                <button
                  type="button"
                  onClick={() => {
                    setCurrentColorFamily(key);
                    setColorNumber(null);
                    setColorDetailBrand(null);
                    setNavBarIndex(key === 'popular' ? null : '00');
                  }}
                  id={isColorFamilySelected ? SELECTED_FAMILY_ID : ''}
                  key={key}
                  className={cx('colorFamily', { selected: isColorFamilySelected })}
                >
                  <img
                    alt={key}
                    className={cx({ selected: isColorFamilySelected })}
                    src={buildColorFamilyImgUrl(key)}
                  />
                  <p
                    className={cx('colorFamilyText', { selected: isColorFamilySelected })}
                    data-bugbear-id="colorFamilyText"
                  >
                    {name}
                  </p>
                </button>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};