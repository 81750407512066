import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Neutral Paint',
  introduction: <p>Introduce elegance and versatility into your décor with timeless neutral paint colors. Before starting your next project, take a moment to explore the different types of neutral colors available and learn about the essential paint tools you’ll need to get started.</p>,
  listTitle: 'Neutral Paint Colors',
  listIntroduction: 'Neutrals can go with just about any other color, making them a perennial favorite and very on trend at the moment. The right neutral shade and application can breathe new life into any space. Here are some of the most popular neutrals to consider for your home:',
  shadesList: [
    {
      title: 'Gray',
      copy: 'There are several gray paint colors to choose from. Narrow down your options by deciding whether you want a cool or warm tone. Cool grays feature undertones of green, blue, or purple, while warm grays have undertones of yellow or brown. If you’re interested in warm grays, greige paint – a combination of gray and beige – makes an excellent choice for bedrooms, dining rooms, and living rooms. Stick with cool grays if you’re going for a modern look -- they work well with dark floors, white cabinets, and white marble.'
    }, {
      title: 'Beige',
      copy: 'This pale, sandy color complements a wide array of décors ranging from traditional to modern. Like gray, beige paint can be either warm or cool. Warm beige colors have yellow undertones, and cool beige colors have pinkish undertones. Try teal or turquoise as complementary colors if you’re interested in warming up your beige. White trim goes well with all beige tones, no matter how light or dark.'
    }, {
      title: 'Taupe',
      copy: 'Taupe is a mix between gray and brown and looks great as a backdrop or subtle accent. Decide whether you want a warm taupe, which has a reddish undertone, or a cool taupe, which has a greenish undertone. For a room with a cool taupe palette, include mirrors and metal accents to achieve a sleek look.'
    }
  ],
  tipsTitle: 'Tips on Painting Tools',
  tips: [
    <>
      Before kicking off your decor project you’ll want to make sure you have the right
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies/N-5yc1vZci1w">painting tools</SeoLink>
      {' '}
      on hand.
    </>
  ],
  toolsTips: [
    <>
      <BoldText>
        Rollers:
      </BoldText>
      {' '}
      A
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Rollers/N-5yc1vZaqpy">
        paint roller
      </SeoLink>
      {' '}
      is an essential tool to have. Be sure to pay attention to the thickness of the nap.
      {' '}
      Smoother surfaces need a thin nap, while rougher surfaces require a thick nap.
    </>,
    <>
      <BoldText>Tapes:</BoldText>
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu">
        Painter’s tape
      </SeoLink>
      {' '}
      is another must-have for any painting project. Be sure to use this instead of masking tape,
      {' '}
      which leaves a sticky residue and can peel the paint.
    </>
  ],
  closing: 'Whether you’re interested in gray, beige, or taupe, a neutral color palette can elevate any room when using the right shades and accents.'
};
