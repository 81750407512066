import React from 'react';
import classNames from 'classnames/bind';
import { string, oneOfType, element } from 'prop-types';
import styles from './SeoCopyBlock.module.scss';

const cx = classNames.bind(styles);

const SeoLink = ({ href, children }) => (
  <a className={cx('u__default-link')} href={href}>
    {children}
  </a>
);

SeoLink.propTypes = {
  href: string.isRequired,
  children: oneOfType([element, string]).isRequired
};

export default SeoLink;
