export const ADD_COLOR_TO_COMPARE = 'ADD_COLOR_TO_COMPARE';
export const REMOVE_ALL_COLORS_FROM_COMPARE = 'REMOVE_ALL_COLORS_FROM_COMPARE';
export const REMOVE_COLOR_FROM_COMPARE = 'REMOVE_COLOR_FROM_COMPARE';

export function addColorToCompare({
  colorName, vendor, colorNumber, rgb
}) {
  return {
    type: ADD_COLOR_TO_COMPARE,
    payload: {
      colorName,
      colorBrand: vendor,
      colorNumber,
      rgb
    }
  };
}

export function removeColorFromCompare(colorToRemove) {
  return {
    type: REMOVE_COLOR_FROM_COMPARE,
    payload: colorToRemove
  };
}

export function removeAllColorsFromCompare() {
  return {
    type: REMOVE_ALL_COLORS_FROM_COMPARE,
    payload: {}
  };
}