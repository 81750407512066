import React from 'react';
import classNames from 'classnames/bind';
import { string, oneOf } from 'prop-types';
import { Heading } from '@thd-olt-component-react/core-ui';

import styles from './Title.module.scss';

const cx = classNames.bind(styles);

export const Title = ({
  step,
  title,
  className,
  level
}) => {
  const isInspirationTitle = className === 'inspiration';

  return (
    <Heading
      className={cx('title', { isInspirationTitle: className })}
      data-testid="title-top"
      level={level}
    >
      <div data-bugbear-id={`${step.replace(' ', '')}Title`}>
        { step && <span data-testid="title-step" className={cx('titleStep')}>{step} </span> }
        { title && <span data-testid="title-main" className={isInspirationTitle ? cx(className) : null}>{title}</span> }
      </div>
    </Heading>
  );
};

Title.propTypes = {
  title: string.isRequired,
  step: string,
  className: string,
  level: oneOf(['1', '2', '3', '4', '5', '6'])
};

Title.defaultProps = {
  step: '',
  className: '',
  level: '2'
};