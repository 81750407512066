import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Red Paint',
  introduction: <p>Red is a strong color that can evoke a wide range of emotions, from love and passion to energy and excitement. They key to success with red paint colors is to use them strategically as accent colors or in active spaces. Before getting started, follow our tips for choosing some shades of red that might work well in your home and essential painting tools you’ll need to get started.</p>,
  listTitle: 'Shades of Red',
  listIntroduction: 'Here are a few types of red to consider for your home:',
  shadesList: [
    {
      title: 'Crimson Red',
      copy: 'Crimson red is a strong, deep hue that grabs your attention with its boldness and sophistication. It also offers a lot of versality. You can use crimson as a backdrop for your dining room and include bright artwork to amplify the color’s dramatic effect. If you want to incorporate a red accent wall into your home, crimson is one of the best types of red to make an eye-catching statement. Pair the color with greens or deep bronzes if you’re going for a more traditional look. For modern décor, stick with complementary colors such as pink or beige.'
    }, {
      title: 'Brick Red',
      copy: 'A warm shade with undertones of brown, brick red is a bold color without being too overwhelming. This makes it an excellent backdrop for a cozy living room or a tasteful accent in a neutral-colored kitchen. To infuse more warmth into your space, pair brick red with rich gold or chocolate brown. For more brightness, include white or cream tones in your room.'
    }, {
      title: 'Burgundy',
      copy: 'This is one of the most relaxing and sophisticated shades of red, thanks to its undertones of purple and brown. Choose burgundy to add coziness and warmth to a bedroom, office, or living room. For complimentary colors, stick with light gray or charcoal gray. You can also experiment with gold or umber to further warm up your space.'
    }
  ],
  tipsTitle: 'Painting Tool Tips',
  tips: [
    <>
      If you decide to do your painting project by yourself, it’s important to assemble the proper
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies/N-5yc1vZci1w">painting tools</SeoLink>
      {' '}
      before getting started.
    </>
  ],
  toolsTips: [
    <>
      <BoldText>Brushes:</BoldText>
      {' '}
      When shopping for a
      {' '}
      <SeoLink
        href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac"
      >
        paint brush
      </SeoLink>
      , decide whether you need one with natural or synthetic bristles. Natural brushes are softer
      {' '}
      and pair well with oil-based paints, while synthetic brushes are more versatile and can be
      {' '}
      used with a variety of paints.
    </>,
    <>
      <BoldText>Tapes:</BoldText>
      {' '}
      <SeoLink
        href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu"
      >
        Painter’s tape
      </SeoLink>
      {' '}
      is another key accessory to have. Don’t choose masking tape over painter’s tape because it
      {' '}
      leaves a sticky residue and can peel paint off your finish.
    </>
  ],
  closing: 'Whether you’re looking to spice up your kitchen, living room, or dining room, there are plenty of red paint colors to choose from to achieve your desired look.'
};
