import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';
import { Checkbox } from '@one-thd/sui-atomic-components';
import { addColorToCompare, removeColorFromCompare } from '../compareActions';
import { DESKTOP } from '../../../constants/Constants';
import { pushAnalyticsEvent } from '../../../utils/analyticsUtils';
import { ColorDetailsContext, ColorFamilyContext, CompareContext, LayoutContext } from '../../../context/Contexts';
import styles from './CompareCheckbox.module.scss';

const cx = classNames.bind(styles);

export const CompareCheckbox = ({ contentColor }) => {
  const { channel } = useContext(LayoutContext);
  const { activeColor } = useContext(ColorFamilyContext);
  const { colorDetailsData } = useContext(ColorDetailsContext);
  const { compareState, dispatchCompare } = useContext(CompareContext);
  const maxColors = channel === DESKTOP ? 8 : 4;

  const getDefaultIsCheckedValue = () => {
    const currentColor = (activeColor.colorName && activeColor.colorNumber) ? activeColor : colorDetailsData.paintColorDetails.color;
    return compareState.colorsToCompare.some((colorToCompare) => {
      return (
        colorToCompare.colorName === currentColor.colorName
          && colorToCompare.colorNumber === currentColor.colorNumber
          && colorToCompare.rgb === currentColor.rgb
          && colorToCompare.colorBrand === currentColor.vendor
      );
    });
  };

  const [isChecked, setIsChecked] = useState(getDefaultIsCheckedValue());

  useEffect(() => {
    setIsChecked(getDefaultIsCheckedValue());
  }, [activeColor, compareState.colorsToCompare]);

  const handleCheckboxOnChange = (checked) => {
    const color = (activeColor.colorName && activeColor.colorNumber) ? activeColor : colorDetailsData;
    setIsChecked(checked);
    if (checked) {
      dispatchCompare(addColorToCompare(color));
      pushAnalyticsEvent({
        category: {
          primaryCategory: 'interaction'
        },
        eventInfo: {
          eventName: 'compare color click'
        }
      });
    } else {
      dispatchCompare(removeColorFromCompare(color));
    }
  };

  return (
    <div className={cx('checkbox', 'checkbox-btn', 'compare-checkbox', 'sui-items-center')}>
      <Checkbox
        checked={isChecked}
        disabled={compareState.colorsToCompare.length >= maxColors && !isChecked}
        id="compare-color-checkbox"
        onChange={(event) => handleCheckboxOnChange(event.target && event.target.checked)}
        value="compare"
      />
      <span className={cx(`checkbox-btn__label--${contentColor}`)}>
        {channel?.toUpperCase() === DESKTOP ? 'Compare Color' : 'Compare'}
      </span>
    </div>
  );
};

CompareCheckbox.propTypes = {
  contentColor: string.isRequired,
};