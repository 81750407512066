import React, { useContext, useState } from 'react';
import classNames from 'classnames/bind';

import { DropDown, DropDownItem, Row } from '@thd-olt-component-react/core-ui';
import {
  DESKTOP,
  MOBILE,
  STEP_2,
  STEP_3
} from '../../constants/Constants';
import { Title } from '../Title/Title';
import { ColorDetailsContext, ColorFamilyContext, LayoutContext } from '../../context/Contexts';

import styles from './BrandFilters.module.scss';

const cx = classNames.bind(styles);

const getStep = (length) => (length > 1
  ? STEP_3
  : STEP_2);

export const BrandFilters = () => {
  const { channel } = useContext(LayoutContext);
  const {
    activeBrand,
    setColorFamilyBrand,
    currentColorFamily,
    colorFamilyData,
    setNavBarIndex
  } = useContext(ColorFamilyContext);
  const { setColorNumber, setColorDetailBrand } = useContext(ColorDetailsContext);
  const { paint = {} } = colorFamilyData || {};
  const {
    dimensions = [],
    searchReport = {},
    navBars
  } = paint;
  const { totalColors } = searchReport;
  const [header, setHeader] = useState(activeBrand?.label ? activeBrand.label : 'All Brands');

  const onBrandFilterClick = (brand) => {
    setColorFamilyBrand(brand);
    setColorNumber(null);
    setColorDetailBrand(null);
    setNavBarIndex(currentColorFamily === 'popular' ? null : '00');
  };

  const pagination = () => {
    const getStartIndex = (navBarIndex, totalNavBarColors) => ((navBarIndex * totalNavBarColors) + 1);
    const getEndIndex = (navBarIndex, totalNavBarColors) => (((navBarIndex + 1) * totalNavBarColors) > totalColors ? totalColors : ((navBarIndex + 1) * totalNavBarColors));
    const getNavBarIndex = () => {
      if (navBars?.length > 0) {
        return parseInt(navBars.find((navBar) => navBar.selected).navBarIndex, 10);
      }
      return 0;
    };
    const getTotalNavBarColors = () => ((currentColorFamily.toLowerCase() === 'popular' || channel !== DESKTOP) ? 70 : 64);
    return (
      <div
        className={cx('paginationContainer')}
        data-bugbear-id="paginationContainer"
      >
        <div>
          <div>
            Showing {getStartIndex(getNavBarIndex(), getTotalNavBarColors())} – {getEndIndex(getNavBarIndex(), getTotalNavBarColors())}
          </div>
        </div>
        <div>of {totalColors} colors</div>
      </div>
    );
  };

  if (!(dimensions?.length > 0)) {
    return null;
  }

  const getReturnWrapper = (children) => {
    return (
      <>
        <Row>
          <Title
            step={getStep(navBars?.length)}
            title={`Select a color ${channel === 'DESKTOP' ? 'below' : ''}`}
            className={cx('filtersTitle')}
          />
        </Row>
        <Row className={cx('filterAndPaginationWrapper')}>
          <div className={cx('filterAndPaginationContent', `bp${channel}`, 'sui-w-full')}>
            {children}
          </div>
        </Row>
      </>
    );
  };

  if (channel === MOBILE) {
    const getDropdownItems = () => {
      const options = [];
      dimensions.forEach(({ refinements, label: brandLabel }) => {
        options.push(
          <DropDownItem
            key={brandLabel}
            onClick={() => {
              setHeader('All Brands');
              onBrandFilterClick(null);
            }}
          >
            All {brandLabel}s
          </DropDownItem>
        );
        refinements.forEach(({ label, refinementKey }) => {
          options.push(
            <DropDownItem
              key={label}
              onClick={() => {
                setHeader(label?.toUpperCase());
                onBrandFilterClick(refinementKey);
              }}
            >
              {label.toUpperCase()}
            </DropDownItem>);
        });
      });
      return options.map((option) => option);
    };

    return getReturnWrapper(
      <>
        <DropDown header={header} hideOnSelection expandOnClick>
          {getDropdownItems()}
        </DropDown>
        <div className={cx('titleAndPagination')}>
          {pagination()}
        </div>
      </>
    );
  }

  const filters = (
    <>
      {dimensions.map(({ refinements, label: brandLabel }) => (
        <div className={cx('brandFiltersContainer')} key={brandLabel} data-bugbear-id="brandFiltersContainer">
          <div
            className={cx('filtersLabelText')}
            data-bugbear-id="filtersLabelText"
          >
            Filters
          </div>
          <div className={cx('filters')}>
            <div
              onClick={() => onBrandFilterClick(null)}
              className={cx('brandFilter', { active: !activeBrand.refinementKey })}
              data-bugbear-id="brandFilter"
            >
              All {brandLabel}s
            </div>
            {refinements.map(({ label, refinementKey }) => (
              <div
                key={refinementKey}
                onClick={() => onBrandFilterClick(refinementKey)}
                className={cx('brandFilter', { active: activeBrand.refinementKey === refinementKey })}
                data-bugbear-id="brandFilter"
              >
                {label}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
  return getReturnWrapper(
    <>
      {filters}
      {pagination()}
    </>
  );
};