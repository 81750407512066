import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Gray Paint',
  introduction: <p>Gray is an elegant neutral color that works well with just about any décor ranging from traditional to modern. As you explore gray paint colors for your upcoming project, keep these tips in mind for finding the appropriate shade for your space.</p>,
  listTitle: 'Gray Paint Colors',
  listIntroduction: 'With so many grays to choose from, selecting the right shade for your space can be daunting. Here are a few beautiful shades of gray to consider for your next paint job:',
  shadesList: [
    {
      title: 'Greige',
      copy: 'Greige paint is a mix between gray and beige. The gray brings out a richer color, while the beige provides a level of warmth, making it one of the best gray paint colors for a bedroom, dining room, or kitchen. If you choose a greige paint that leans more gray than beige, complement it with blue, white or cool colors.'
    }, {
      title: 'Charcoal',
      copy: 'This dark gray offers a bold, elegant tone that invokes a sense of calmness in any space. Charcoal is a perfect gray paint for walls, especially if it’s the backdrop for a stone fireplace or an expansive art collection. You can also pair charcoal with a light gray paint to create balance within your room.'
    }, {
      title: 'Slate Gray',
      copy: 'Slate gray is a cool shade with undertones of azure. It’s one of the most versatile gray paint colors and can be used as a backdrop for bright colors such as coral and green, or as a bold accent in a predominantly white space.'
    }, {
      title: 'Taupe',
      copy: 'Taupe is a shade of gray with brown undertones that brings warmth and softness into a space, especially a bedroom or living room. Pair taupe with off-white trim to give your room a classic rustic appearance.'
    }
  ],
  tipsTitle: 'Painting Tool Tips',
  tips: [
    <>If you’re interested in a gray color palette, it’s important to have the right <SeoLink href="/b/Paint-Paint-Tools-Supplies/N-5yc1vZci1w">painting tools</SeoLink> for your job.</>
  ],
  toolsTips: [
    <>
      <BoldText>Brushes:</BoldText>
      {' '}
      When you’re looking for a
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac">
        paint brush
      </SeoLink>,
      consider the bristle type. Brushes with natural bristles are great for oil-based paints,
      {' '}
      stains, and polyurethanes. Just don’t use them with water-based paints. Brushes with polyester
      {' '}
      bristles work best with latex paints and hold their shape and stiffness well. Choose
      {' '}
      nylon-polyester brushes for extra durability when working with latex paints.
    </>,
    <>
      <BoldText>
        Rollers:
      </BoldText>
      {' '}
      When shopping for
      {' '}
      <SeoLink
        href="/b/Paint-Paint-Tools-Supplies-Paint-Rollers/N-5yc1vZaqpy"
      >
        paint rollers
      </SeoLink>
      , pay attention to the thickness of the nap. Smoother surfaces need thinner naps, while
      {' '}
      rougher surfaces require thicker naps.
    </>
  ],
  closing: 'No matter which room you decide to use a gray color palette in, you’ll have plenty of options to create the look you’ve always envisioned.'
};
