import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import BoldText from './SeoBoldText';
import styles from './SeoCopyBlock.module.scss';
import seoConfig from './config';

import { ColorFamilyContext } from '../../context/Contexts';

const cx = classNames.bind(styles);

const SeoCopyBlock = () => {
  const { currentColorFamily } = useContext(ColorFamilyContext);
  const config = seoConfig[currentColorFamily];

  return config ? (
    <div className={cx('seoCopyBlock')} data-bugbear-id="seoCopyBlockContainer">
      <div>
        <BoldText isBlock>
          {config.title}
        </BoldText>
        <div>{config.introduction}</div>
        {
          typeof config?.listTitle !== 'undefined'
            && (
              <BoldText isBlock>
                {config.listTitle}
              </BoldText>
            )
        }
        {
          typeof config?.listIntroduction !== 'undefined'
            && (
              <p>{config.listIntroduction}</p>
            )
        }
        <ul className={cx('list')}>
          {
            config?.shadesList?.map((item) => (
              <li className={cx('list__item')} key={item.title}>
                <BoldText>{item.title}:</BoldText> {item.copy}
              </li>
            ))
          }
        </ul>
        {
          config.shadesRecommendation
          && (
            <p>{ config.shadesRecommendation }</p>
          )
        }
        {
          config.tips
          && (
            <>
              <BoldText isBlock>
                { config.tipsTitle }
              </BoldText>
              {
                config.tips.map((tip) => (
                  <p key={tip}>{ tip }</p>
                ))
              }
            </>
          )
        }
        {
          config.toolsTips
          && (
            <ul className={cx('list')}>
              {
                config.toolsTips.map((tip) => (
                  <li className={cx('list__item')} key={JSON.stringify(tip).length}>
                    { tip }
                  </li>
                ))
              }
            </ul>
          )
        }
        <p>{config.closing}</p>
      </div>
    </div>
  ) : null;
};

export default SeoCopyBlock;