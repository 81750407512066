import React, { useEffect, useRef, useState } from 'react';
import { objectOf, string } from 'prop-types';
import { Loader } from '@thd-olt-component-react/core-ui';

const ImageLoader = ({
  src, alt, className, style
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null);
  const checkImgIsLoaded = () => !!(imageRef && imageRef.current && imageRef.current.complete);

  useEffect(() => {
    let checkImageIntervalId = setInterval(() => {
      if (checkImgIsLoaded()) {
        setIsLoaded(true);
        clearInterval(checkImageIntervalId);
      }
    }, 300);
    return () => { clearInterval(checkImageIntervalId); };
  }, []);

  return (
    <>
      <img
        ref={imageRef}
        src={src}
        alt={alt}
        style={{
          ...style,
          display: isLoaded ? '' : 'none'
        }}
        className={className}
      />

      {!isLoaded
        && (
          <div className="sui-flex sui-align-center sui-justify-center sui-w-full sui-h-full">
            <Loader active />
          </div>
        )}
    </>
  );
};

ImageLoader.propTypes = {
  alt: string.isRequired,
  className: string.isRequired,
  src: string.isRequired,
  style: objectOf(
    string,
  ).isRequired,
};

export default ImageLoader;