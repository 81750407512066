import React from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';

import { getBrandLogoImageUrl } from '../../utils/imageUtils';

import styles from './ColorInfo.module.scss';

const cx = classNames.bind(styles);

const getBrandLogoImageCount = (colorBrand) => {
  return (colorBrand === 'Behr') ? 'single' : 'multiple';
};

export const ColorInfo = ({
  colorBrand, colorName, colorNumber,
  rgb, contentColor, brandLogoWrapperClassName,
  colorNameAndNumberWrapperClassName, colorInfoContainerClassName
}) => (
  <div className={colorInfoContainerClassName}>
    <div className={`${brandLogoWrapperClassName} sui-flex`}>
      {getBrandLogoImageUrl(colorBrand, rgb, contentColor).map((url) => (
        <img
          alt={colorBrand}
          className={cx('brandLogoImage', getBrandLogoImageCount(colorBrand))}
          src={url}
          key={url}
          data-bugbear-id="brandLogo"
        />
      ))}
    </div>

    <div className={colorNameAndNumberWrapperClassName} style={{ color: contentColor }}>
      <div
        className={cx('colorName', contentColor)}
        data-bugbear-id="colorName"
      >
        {colorName}
      </div>
      <div
        className={cx('colorNumber', contentColor)}
        data-bugbear-id="colorNumber"
      >
        <span>#{colorNumber}</span>
      </div>
    </div>
  </div>
);

ColorInfo.propTypes = {
  brandLogoWrapperClassName: string.isRequired,
  colorBrand: string.isRequired,
  colorInfoContainerClassName: string.isRequired,
  colorName: string.isRequired,
  colorNameAndNumberWrapperClassName: string.isRequired,
  colorNumber: string.isRequired,
  contentColor: string.isRequired,
  rgb: string.isRequired
};