import React, { useState, useContext, useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import { Accordion } from './Accordion/Accordion';
import { ColorDetailsInfo } from './ColorDetailsInfo/ColorDetailsInfo';

import { isObjectEmpty, getBackgroundColorFromRgbVal, isBrowser, getActiveFilter } from '../../utils/utils';
import { pushAnalyticsEvent, buildAccordionAnalyticsObj } from '../../utils/analyticsUtils';

import styles from './ColorTile.module.scss';
import { DESKTOP } from '../../constants/Constants';

import { ColorDetailsContext, ColorFamilyContext, LayoutContext } from '../../context/Contexts';

const cx = classNames.bind(styles);

export const ColorTile = ({ tileColorBrand, tileColorName, tileColorNumber, tileRgb }) => {
  const { channel } = useContext(LayoutContext);
  const { colorFamilyData, selectedNavBars } = useContext(ColorFamilyContext);
  const { colorNumber, setColorNumber, setColorDetailBrand, colorDetailsData } = useContext(ColorDetailsContext);
  const numOfCols = colorFamilyData?.paint?.searchReport?.numberOfColumns;

  const [isHovering, setIsHovering] = useState(false);
  const [isSelected, setIsSelected] = useState(colorNumber === tileColorNumber);

  useEffect(() => {
    const tileIsSelected = colorNumber === tileColorNumber;
    if (tileIsSelected !== isSelected) {
      setIsSelected(tileIsSelected);
    }
  }, [colorNumber, tileColorNumber]);

  const toggleHover = (isCurrentlyHovering) => {
    if (isCurrentlyHovering !== isHovering) {
      setIsHovering(isCurrentlyHovering);
    }
  };

  const onMouseEnter = () => {
    toggleHover(true);
  };

  const onMouseLeave = () => {
    toggleHover(false);
  };

  const onColorChipClick = () => {
    const colorFamily = colorFamilyData?.paint?.colorFamily;
    const navBarIndex = selectedNavBars?.navBarIndex;

    if (!isSelected) {
      setColorNumber(tileColorNumber);
      setColorDetailBrand(tileColorBrand);
    }
    const selectedBrandFilter = getActiveFilter(colorFamilyData?.dimensions || []);

    pushAnalyticsEvent(buildAccordionAnalyticsObj({
      tileColorName,
      colorFamily,
      navBarIndex,
      tileColorNumber,
      selectedBrandFilter,
      colorFilter: `all ${colorFamily}s`
    }));
  };

  const scrollIntoView = (element) => {
    if (element && isBrowser()) {
      const rectObj = element.getBoundingClientRect();
      // if object is bigger than window or
      // object is below visible zone then scroll to top of object
      if (rectObj.height > window.innerHeight
        || (rectObj.top + rectObj.height) > window.innerHeight
      ) {
        window.scroll({
          top: (window.pageYOffset || document.documentElement.scrollTop)
            + rectObj.height - (window.innerHeight - rectObj.top),
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  };

  const content = (isSelected && (colorDetailsData && !isObjectEmpty(colorDetailsData)))
    ? (
      <ColorDetailsInfo
        onClose={() => {
          setIsSelected(false);
          setColorNumber(null);
          setColorDetailBrand(null);
        }}
        tileColorNumber={tileColorNumber}
        tileColorName={tileColorName}
      />
    )
    : <div />;

  return (
    <Accordion
      className={cx(`colorTile-${numOfCols}`)}
      content={content}
      contentClassName={cx('accordionContent')}
      isOpened={isSelected}
      onResizeCb={scrollIntoView}
    >
      <div
        className={cx('colorTileContent')}
        onClick={() => onColorChipClick()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ background: getBackgroundColorFromRgbVal(tileRgb) }}
        data-testid="colorTile"
        data-bugbear-id="colorTile"
      >
        {(channel === DESKTOP && (isHovering || isSelected))
          && (
            <div className={cx('hoverTile')}>
              <div className={cx('vendorNameText')}>{tileColorBrand}</div>
              <div className={cx('colorNameText')}>{tileColorName}</div>
            </div>
          )}
      </div>
    </Accordion>
  );
};

ColorTile.propTypes = {
  tileColorBrand: string.isRequired,
  tileColorName: string.isRequired,
  tileColorNumber: string.isRequired,
  tileRgb: string.isRequired
};