import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Purple Paint',
  introduction: <p>Give your space a dose of boldness and personality with purple paint. Purple is often associated with royalty and luxury, but it can also inspire creativity and bring calmness. Explore some of the shades and tones available, then get some tips on finding the right paint tools for your next décor job.</p>,
  listTitle: 'Shades of Purple',
  listIntroduction: 'When executed properly, a purple color palette can have a distinguishing effect in any room. Here are some shades of purple to consider using in your home:',
  shadesList: [
    {
      title: 'Lavender',
      copy: 'This lighter, gentler shade of purple provides plenty of versatility. If you’re interested in a purple bedroom, lavender paint makes an excellent backdrop because it brings a sense of serenity and peacefulness. It can also bring a level of sophistication into a space, especially when paired with neutral colors. For a bolder, more striking appearance, combine lavender with pink, red, or turquoise.'
    }, {
      title: 'Orchid',
      copy: 'Orchid is a rich shade of purple with tones of pink, blue, and gray. Whether it’s used as a backdrop or accent, orchid paint is bound to make a statement in any space. To make orchid really pop, go with neutrals such as gray, cream, or off-white for your trim. You can also experiment with dark wood accessories to add a flourish of sophistication to your room.'
    }, {
      title: 'Lilac',
      copy: 'This soft, light shade of purple has undertones of blue, red, and white and evokes elements of elegance, grace, and femininity. You can choose from a wide variety of pale, rich, and deep lilac tones to infuse your décor. Try pairing lilac with off-white, natural wood, and pink to give your space a light, airy feel. For a more contrasting look, experiment with hues such as olive green, yellow, and orange.'
    }
  ],
  tipsTitle: 'Paint Tool Tips',
  tips: [
    'Make sure you have the right painting supplies on hand before kicking off your purple paint project.'
  ],
  toolsTips: [
    <>
      <BoldText>Rollers:</BoldText>
      {' '}
      When searching for a
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Rollers/N-5yc1vZaqpy">
        paint roller
      </SeoLink>
      , choose between a synthetic or natural cover. Synthetic covers are best used with water-based
      {' '}
      paints, while natural covers work well with oil-based paints.
    </>,
    <>
      <BoldText>Tape:</BoldText>
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu">
        Painter’s tape
      </SeoLink>
      {' '}
      is another must-have item for your toolbox. Be sure to use this instead of masking tape, which
      {' '}
      leaves a sticky residue behind and can peel paint off.
    </>,
    <>
      <BoldText>Drop Cloths:</BoldText>
      {' '}
      It's important to have a
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tarps-Drop-Cloths-Plastic-Sheeting-Drop-Cloths/N-5yc1vZci1q">
        drop cloth
      </SeoLink>
      {' '}
      to protect your flooring and furnishings from drips, spills, and splatters. Drop cloths are
      {' '}
      made from a variety of materials, so consider the type of cloth best suited for your
      {' '}
      application.
    </>
  ],
  closing: 'Whether you’re looking to spruce up your bedroom, living room, or bathroom, purple paint is an inspired choice that comes in a variety of shades to help you realize your decor vision.'
};
