import React from 'react';
import SeoLink from '../SeoLink';

export default {
  title: 'Black Paint',
  introduction: <p>Add sleekness, depth and coziness to any room with black paint. Before picking out a shade of black, make sure you’re familiar with the types of sheens available and how to best use the color in your décor.</p>,
  listTitle: 'Types of Paint Sheens',
  listIntroduction: 'Consider the varieties of sheens when shopping for black paint for your walls.',
  shadesList: [
    {
      title: 'Matte',
      copy: 'Also known as flat, matte paints are excellent for concealing imperfections in irregular surfaces due to their low reflectivity. Matte black paint does scuff easily, though, which means it’s best for low-traffic areas. Matte paint is regularly used on ceilings, but when used on a wall, it can help boost the richness of a deep color.'
    }, {
      title: 'Eggshell',
      copy: 'This type of paint provides a low-sheen, smooth surface similar to an eggshell. Eggshell is easier to clean than matte, making it a great choice for high-traffic rooms such as the living room, dining room, and bedroom.'
    }, {
      title: 'Satin',
      copy: 'A bit shinier than eggshell, satin provides an elegant finish and soft sheen. This paint cleans well and is resistant to fingerprints, scuffs, and stains, so it’s ideal for bathrooms and kitchens.'
    }, {
      title: 'Semi-Gloss',
      copy: 'Choose semi-gloss if you’re seeking a shiny finish without the wet look. Semi-gloss is often used to coat wall trims, interior shutters, and banisters. It offers high moisture resistance, but it does reflect light, which can reveal imperfections.'
    }, {
      title: 'Gloss',
      copy: 'Gloss is the shiniest and most durable paint finish available. Because of its high sheen, gloss is a great option for paneled interior doors. Because gloss is highly reflective, it will expose surface flaws.'
    }
  ],
  tipsTitle: 'Decorating With Black Walls',
  tips: [
    'Consider the room and the sheen you prefer to choose the best black wall paint for your home. Black paint can be used in your bedroom to create a dramatic backdrop for artwork, emphasize your headboard or to provide a striking accent alongside a white wall.',
    'Black is a neutral color, so the possibilities are endless when it comes to complementing this shade with other colors within any room. For a classic look, try black walls with gold accents throughout the room. Experiment with incorporating cream-colored flourishes such as drapes or end tables if you want to create a more soothing atmosphere.',
    'Since black absorbs light, consider the type of lighting in your room. Track lights work well because you can angle them to hit the dark spots. Chandeliers and pendant lights are also great choices that add style and sophistication to any room.'
  ],
  closing:
  <>
    Whether you’re interested in jet black paint or true black paint for your décor, black can make
    a bold statement in your bedroom, kitchen, bathroom and other areas of your home. Don’t forget
    to pick up essential tools including a
    {' '}
    <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac">
      paint brush
    </SeoLink>,
    {' '}
    <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu">
      painter’s tape
    </SeoLink>
    {' '}
    and a
    {' '}
    <SeoLink
      href="/b/Paint-Paint-Tools-Supplies-Tarps-Drop-Cloths-Plastic-Sheeting-Drop-Cloths/N-5yc1vZci1q"
    >
      drop cloth
    </SeoLink>
    {' '}
    when you decide to paint.
  </>
};
