import orange from './orange';
import black from './black';
import blue from './blue';
import brown from './brown';
import gray from './gray';
import neutral from './neutral';
import purple from './purple';
import white from './white';
import yellow from './yellow';
import popular from './popular';
import red from './red';
import green from './green';

export default {
  orange,
  black,
  blue,
  brown,
  gray,
  neutral,
  popular,
  purple,
  white,
  yellow,
  red,
  green
};
