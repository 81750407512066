import React, {
  useContext,
  useState,
  useRef,
  useEffect
} from 'react';
import {
  oneOfType,
  arrayOf,
  node,
  string,
  bool,
  func
} from 'prop-types';
import { Transition } from 'react-transition-group';
import ResizeObserver from 'resize-observer-polyfill';

import { LayoutContext } from '../../../context/Contexts';
import { MOBILE } from '../../../constants/Constants';

const resizeGapAdmission = 20;
const duration = 300;

export const Accordion = ({
  children,
  className,
  content,
  contentClassName,
  isOpened,
  onResizeCb,
}) => {
  const [containerHeight, setContainerHeight] = useState(null);
  const [contentHeight, setContentHeight] = useState(null);
  const [isAccordionHeightSet, setIsAccordionHeightSet] = useState(false);
  const contentRef = useRef();
  const resizeObserverRef = useRef(null);
  const { channel } = useContext(LayoutContext);
  // TODO: calculate childrenHeight using ref or whatever
  const childrenHeight = channel === MOBILE ? 80 : 90;

  const hasClearance = (height = 0, prevHeight = 0) => {
    return Math.abs(height - prevHeight) > resizeGapAdmission;
  };

  const setContainerStyles = () => {
    if (contentRef.current) {
      const tempContentHeight = Array.from(contentRef.current.children)
        .reduce((total, nextElement) => total + nextElement.clientHeight, 0);
      const tempContainerHeight = tempContentHeight + childrenHeight;

      if (tempContainerHeight !== containerHeight
        && hasClearance(tempContainerHeight, containerHeight)
      ) {
        setContainerHeight(tempContainerHeight);
        setContentHeight(tempContentHeight);
        if (isOpened) {
          onResizeCb(contentRef.current);
        }
      }
      setIsAccordionHeightSet(true);
    }
  };

  const onResize = () => {
    if (isOpened) {
      setContainerStyles();
    }
  };

  const observeSizes = () => {
    if (!resizeObserverRef.current) {
      resizeObserverRef.current = new ResizeObserver(onResize);
      resizeObserverRef.current.observe(contentRef.current);
    }
  };

  const clearContainerStyles = () => {
    if (contentRef.current) {
      setContainerHeight(null);
      setContentHeight(null);
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      observeSizes();
    }
    setContainerStyles();
    return (() => {
      if (resizeObserverRef.current && typeof resizeObserverRef.current.disconnect === 'function') {
        resizeObserverRef.current.disconnect();
      }
    });
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      observeSizes();
    }
    onResize();
  });

  const containerStyles = containerHeight !== null ? { height: `${containerHeight}px` } : {};
  const contentStyles = contentHeight !== null ? { height: `${contentHeight}px` } : {};

  return (
    <div className={className} style={{ ...containerStyles }} data-bugbear-id="colorTileContent">
      {children}
      <Transition
        appear
        in={isOpened}
        onEntering={setContainerStyles}
        onExiting={clearContainerStyles}
        timeout={duration}
        unmountOnExit
      >
        <div
          className={contentClassName}
          style={contentStyles}
          data-bugbear-id="accordion"
        >
          <div
            className="sui-w-full"
            ref={contentRef}
            data-bugbear-id="accordionContentWrapper"
          >
            {isAccordionHeightSet ? content : null}
          </div>
        </div>
      </Transition>
    </div>
  );
};

Accordion.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  className: string.isRequired,
  content: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  contentClassName: string.isRequired,
  isOpened: bool.isRequired,
  onResizeCb: func.isRequired,
};