import React, { useContext, useEffect } from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames/bind';

import { Carousel, Image } from '@thd-olt-component-react/core-ui';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import { WarningFilled } from '@one-thd/sui-icons';
import { ColorDetailsLayout } from './ColorDetailsLayout/ColorDetailsLayout';
import { BuyButtons } from '../../BuyButtons/BuyButtons';
import { pushAnalyticsEvent, buildBuySampleAnalyticsObj } from '../../../utils/analyticsUtils';
import { updateColorDetailsData, getBackgroundColorFromRgbVal } from '../../../utils/utils';
import { getLifeStyleImages } from '../../../utils/imageUtils';
import { DESKTOP } from '../../../constants/Constants';

import styles from './ColorDetailsInfo.module.scss';
import { ColorDetailsContext, ColorFamilyContext, LayoutContext } from '../../../context/Contexts';

const cx = classNames.bind(styles);

export const ColorDetailsInfo = ({ onClose, tileColorNumber, tileColorName }) => {
  const { setActiveColor, activeColor } = useContext(ColorFamilyContext);
  const { colorDetailsData } = useContext(ColorDetailsContext);
  const { channel } = useContext(LayoutContext);
  const {
    colorNumber: activeColorNumber,
    vendor: activeVendor,
    rgb: activeRgb
  } = activeColor || {};

  useEffect(() => {
    if (colorDetailsData) {
      const defaultColorDetailsData = updateColorDetailsData(colorDetailsData.paintColorDetails, tileColorNumber);
      if (defaultColorDetailsData.colorNumber) {
        setActiveColor(defaultColorDetailsData);
      }
    }
  }, [colorDetailsData]);

  const handleBuySampleAnalytics = (itemId) => {
    pushAnalyticsEvent(buildBuySampleAnalyticsObj(itemId, 'paint details'));
  };

  const updateAnchorColor = (colorNumber) => {
    const updatedColorDetailsData = updateColorDetailsData(colorDetailsData.paintColorDetails, colorNumber);
    setActiveColor(updatedColorDetailsData);
  };

  const carouselImageStyle = { background: getBackgroundColorFromRgbVal(activeRgb) };
  let images = getLifeStyleImages(channel);

  if (!colorDetailsData?.paintColorDetails) {
    const errorWrapperHeight = channel?.toUpperCase() === DESKTOP ? '300' : '400';
    return (
      <div className={cx('colorDetailsInfo', 'colorDetailsBody')}>
        <div
          className="sui-flex sui-flex-col sui-px-2 sui-items-center sui-justify-center"
          style={{ height: `${errorWrapperHeight}px` }}
        >
          <WarningFilled size="regular" color="warning" />
          <div className="sui-pt-3">
            <Typography variant="h3" color="subtle">{`${tileColorName} #${tileColorNumber} is currently unavailable.`}</Typography>
            <Typography variant="h3" color="subtle">Please select a different color or visit the store for a sample.</Typography>
          </div>
        </div>
        <button
          type="button"
          className={cx('closeButton', 'sui-bg-primary')}
          data-testid="color-details-info-close"
          onClick={onClose}
          data-bugbear-id="colorDetailsInfoCloseButton"
        >
          <Image asset="close-symbol-orange.svg" alt="close" width="30" height="30" />
        </button>
      </div>
    );
  }

  return (
    <div className={cx('colorDetailsInfo', 'colorDetailsBody')}>
      <div>
        <div
          className={cx('colorDetailsCarouselAndInfoContainer')}
          data-testid="colorDetailsinfo"
        >
          <Carousel
            breakpoints={{
              sm: {
                slidesPerView: 1,
                slidesPerGroup: 1
              },
              md: {
                slidesPerView: 1,
                slidesPerGroup: 1
              },
              lg: {
                slidesPerView: 1,
                slidesPerGroup: 1
              },
              xl: {
                slidesPerView: 1,
                slidesPerGroup: 1
              }
            }}
            disableFocus
            loop
            pagination
            peek={false}
            slidesPerView={1}
          >
            {images.map((img) => (
              <Image
                alt="carousel-image"
                className={cx('carouselImage')}
                key={img}
                src={img}
                style={carouselImageStyle}
              />
            ))}
          </Carousel>
          <div className={cx('colorDetailsWrapper')}>
            <ColorDetailsLayout newAnchorColorCallBack={updateAnchorColor} />
          </div>
        </div>
        { channel?.toUpperCase() !== DESKTOP ? (
          <div className="md:sui-pb-7 sui-pb-0">
            <QueryProvider cacheKey="buy-buttons">
              <BuyButtons
                buySampleAnalyticsCb={handleBuySampleAnalytics}
                canonicalURL={colorDetailsData.sample && colorDetailsData.sample.canonicalURL}
                colorDetailsDataProp={{ colorBrand: activeVendor, colorNumber: activeColorNumber }}
                containerClassName={cx('buyButtonsContainer')}
                itemId={colorDetailsData.sample && colorDetailsData.sample.itemId}
                showFreeShippingMessage
                buttonClassName={cx('buyButtonsGridContainer')}
              />
            </QueryProvider>
          </div>
        )
          : null}
      </div>
      <button
        type="button"
        className={cx('closeButton', 'sui-bg-primary')}
        data-testid="color-details-info-close"
        onClick={onClose}
        data-bugbear-id="colorDetailsInfoCloseButton"
      >
        <Image asset="close-symbol-orange.svg" alt="close" width="30" height="30" />
      </button>
    </div>
  );
};

ColorDetailsInfo.propTypes = {
  tileColorNumber: string.isRequired,
  tileColorName: string.isRequired,
  onClose: func.isRequired,
};