import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { func } from 'prop-types';

import { QueryProvider } from '@thd-nucleus/data-sources';
import { Tabs, Tab } from '@thd-olt-component-react/core-ui';

import { ColorInfo } from '../../../ColorInfo/ColorInfo';
import NavBar from '../../../NavBar/NavBar';
import { BuyButtons } from '../../../BuyButtons/BuyButtons';
import { pushAnalyticsEvent, thdAnalyticsEventObj, buildBuySampleAnalyticsObj } from '../../../../utils/analyticsUtils';
import { getBackgroundColorFromRgbVal, getContentColor } from '../../../../utils/utils';
import { CompareCheckbox } from '../../../Compare/CompareCheckbox/CompareCheckbox';
import { DESKTOP } from '../../../../constants/Constants';
import { ColorDetailsContext, ColorFamilyContext, LayoutContext } from '../../../../context/Contexts';

import styles from './ColorDetailsLayout.module.scss';

const cx = classNames.bind(styles);

const analyticsObj = (activeTab, currentColorName, currentColorNumber) => {
  thdAnalyticsEventObj.colorExplore.color = currentColorName;
  thdAnalyticsEventObj.colorExplore.colorID = currentColorNumber;
  thdAnalyticsEventObj.colorExplore.cem = activeTab;
  return thdAnalyticsEventObj;
};

export const ColorDetailsLayout = ({ newAnchorColorCallBack }) => {
  const { channel } = useContext(LayoutContext);
  const { activeColor } = useContext(ColorFamilyContext);
  const { colorNumber } = useContext(ColorDetailsContext);
  const {
    colorName: activeColorName,
    colorNumber: activeColorNumber,
    vendor: activeVendor,
    similarColors,
    coordinatingColors,
    rgb
  } = activeColor || {};

  const [tabIndex, setTabIndex] = useState(0);
  const [similarColorState, setSimilarColorState] = useState(similarColors);
  const [coordinatingColorState, setCoordinatingColorState] = useState(coordinatingColors);

  const activeColorIsEmpty = Object.keys(activeColor).length === 0;

  const handleBuySampleAnalytics = (itemId) => {
    pushAnalyticsEvent(buildBuySampleAnalyticsObj(itemId, 'paint details'));
  };

  const onChange = ({ index }) => {
    setTabIndex(index);
    newAnchorColorCallBack(colorNumber);
  };

  const onColorBubbleClick = (activeTab) => {
    return (currentColor) => {
      newAnchorColorCallBack(currentColor.colorNumber);
      pushAnalyticsEvent(analyticsObj(activeTab, currentColor.colorNumber, currentColor.colorName));
    };
  };

  useEffect(() => {
    if (similarColors?.length && coordinatingColors?.length) {
      setSimilarColorState(similarColors);
      setCoordinatingColorState(coordinatingColors);
    }
  }, [activeColor]);

  if (activeColorIsEmpty) {
    return null;
  }

  const contentColor = (channel?.toUpperCase() === DESKTOP) ? 'black' : getContentColor(rgb);
  const getColorNavBar = (colorType) => (
    <NavBar
      data={colorType === 'Similar' ? similarColorState : coordinatingColorState}
      maxTiles={colorType === 'Similar' ? '7' : '6'}
      onNavBarClick={onColorBubbleClick(colorType)}
      shape="circle"
    />
  );

  return (
    <div
      className={cx('colorDetailsContainer')}
      style={{ background: channel?.toUpperCase() !== DESKTOP ? getBackgroundColorFromRgbVal(rgb) : 'none' }}
    >
      <div className={cx('colorInfoWrapper')}>
        <ColorInfo
          brandLogoWrapperClassName={cx('brandLogoWrapper')}
          colorBrand={activeVendor}
          colorInfoContainerClassName={cx('colorInfoContainer')}
          colorName={activeColorName}
          colorNameAndNumberWrapperClassName={cx('colorNameAndNumberWrapper')}
          colorNumber={activeColorNumber}
          contentColor={contentColor}
          rgb={rgb}
        />
        <div className={cx('checkboxBtn')}>
          <hr />
          <CompareCheckbox contentColor={contentColor} />
        </div>
      </div>
      {channel?.toUpperCase() === DESKTOP ? (
        <>
          <hr />
          <div className={cx('navbarTitle')}>Similar Colors</div>
          {getColorNavBar('Similar')}
          <hr />
          <div className={cx('navbarTitle')}>Coordinating Colors</div>
          {getColorNavBar('Coordinating')}
          <hr />
          <QueryProvider cacheKey="buy-buttons">
            <BuyButtons
              buySampleAnalyticsCb={handleBuySampleAnalytics}
              colorDetailsDataProp={{ colorBrand: activeVendor, colorNumber: activeColorNumber }}
              containerClassName={cx('buyButtonsContainer')}
              showFreeShippingMessage
              buttonClassName={cx('buyButtonsGridContainer')}
            />
          </QueryProvider>
        </>
      ) : (
        <div className={cx('colorOptions')}>
          <Tabs gridStyle className={cx('tabs')} value={tabIndex} onChange={onChange}>
            <Tab label="Similar Colors" className={cx('tabItem', { active: tabIndex === 0 })} />
            <Tab label="Coordinating Colors" className={cx('tabItem', { active: tabIndex === 1 })} />
          </Tabs>
          <div className={cx('selectedTabContent')}>
            <div label={`${tabIndex === 0 ? 'Similar' : 'Coordinating'} Colors`}>
              {getColorNavBar(tabIndex === 0 ? 'Similar' : 'Coordinating')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ColorDetailsLayout.propTypes = {
  newAnchorColorCallBack: func.isRequired
};