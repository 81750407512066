import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { Carousel, Image } from '@thd-olt-component-react/core-ui';

import { DESKTOP } from '../../constants/Constants';
import { buildItems } from './RelatedItemsCarouselData';
import { LayoutContext } from '../../context/Contexts';
import { Title } from '../Title/Title';

import styles from './RelatedItemsSection.module.scss';

const cx = classNames.bind(styles);

const getItemsMarkup = () => {
  const items = buildItems();
  return items.map(({ src, title, link }) => (
    <a className={cx('slide')} href={link} key={title}>
      <div className={cx('image-container')}>
        <Image className="sui-m-auto" src={src} alt={title} lazy={false} />
      </div>
      <span className={cx('slide-caption')}>{title}</span>
    </a>
  ));
};

export const RelatedItemsSection = () => {
  const { channel } = useContext(LayoutContext);

  return (
    <section className={cx('container', 'sui-w-full')}>
      <Title title="Looking for something more specific?" />
      {
        (channel === DESKTOP)
          ? (
            <Carousel
              breakpoints={{
                lg: {
                  slidesPerView: 7,
                  slidesPerGroup: 6
                },
                xl: {
                  slidesPerView: 7,
                  slidesPerGroup: 6
                }
              }}
              disableFocus
              loop
              slidesPerGroup={6}
              slidesPerView={7}
            >
              {getItemsMarkup()}
            </Carousel>
          )
          : <div className={cx('nativeScroll')}>{getItemsMarkup()}</div>
      }
    </section>
  );
};