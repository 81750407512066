import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Blue Paint',
  introduction: <p>Add an element of serenity and relaxation into your home by incorporating blue paint colors into your design. Grab some swatches to decide what shade of blue is best for your space, then keep the following tips in mind when planning your next paint job.</p>,
  listTitle: 'Types of Blue Paint Colors',
  listIntroduction: 'There are many shades in the blue color palette that provide different effects when used in your décor. Here are a few shades to consider:',
  shadesList: [
    {
      title: 'Light Blues',
      copy: 'Baby blue color – or light blue – is a classic choice that’s easy on the eyes and helps boost relaxation. When painted on your walls, baby blue has a softening effect, making it one of the best blue paint colors for bedrooms and bathrooms. When it comes to complementing other colors, baby blue works well with bright white, beige, and gold. Sky blue paint is another great selection that provides similar effects.'
    }, {
      title: 'Periwinkle',
      copy: 'Described as a mix between lavender and blue, periwinkle provides a fresher and more whimsical look to your space. Stick with gray colors for trims and accents. To warm up your space, complement periwinkle with burnt orange, brown or sharp shades of black.'
    }, {
      title: 'Electric Blue',
      copy: 'Pick this deep-toned shade of blue if you’re looking for a burst of energy within your space. Electric blue works perfectly as an accent wall, but you can also try incorporating this color on your kitchen cabinets or dining room wall. It’s best to choose white or off-white colors for any trims and accents.'
    }
  ],
  tipsTitle: 'More Painting Tips',
  tips: [
    <>Before starting your design project, stock up on the <SeoLink href="/b/Paint-Paint-Tools-Supplies/N-5yc1vZci1w">painting tools</SeoLink> you’ll need to get the job done.</>
  ],
  toolsTips: [
    <>
      <BoldText>Brushes:</BoldText>
      {' '}
      When looking for a
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac">
        paint brush
      </SeoLink>,
      {' '}
      decide whether you need a flat or an angle brush, or a thin angle one. Flat brushes are best
      {' '}
      for large surfaces. Angle brushes are ideal for cutting in at the ceiling or painting trim.
      {' '}
      Thin angle brushes are excellent for detail work.
    </>,
    <>
      <BoldText>Tapes:</BoldText>
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu">
        Painter`s tape
      </SeoLink>
      {' '}
      is another important tool to keep on hand. Painter’s and masking tape are often
      {' '}
      interchangeable, but there are differences between the two. Masking tape is more affordable
      {' '}
      than painter’s tape, but painter’s tape doesn’t leave sticky residue, won’t pucker when it’s
      {' '}
      painted over, and leaves clean lines when it’s removed.
    </>
  ],
  closing: 'Whether you’re looking to spruce up your bathroom, living room, or bedroom, blue paint colors are on trend and are a great choice to instantly freshen up any room.'
};
