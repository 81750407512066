import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import {
  arrayOf,
  shape as shapeProp,
  func,
  string
} from 'prop-types';
import { Checkmark } from '@one-thd/sui-icons';
import { getBackgroundColorFromRgbVal, getContentColor } from '../../utils/utils';
import { DESKTOP } from '../../constants/Constants';
import styles from './NavBar.module.scss';

import { LayoutContext } from '../../context/Contexts';

const cx = classNames.bind(styles);

const NavBar = ({
  data,
  maxTiles,
  onNavBarClick,
  shape,
  additionalStyle
}) => {
  const { channel } = useContext(LayoutContext);
  const navBarClasses = cx(
    'navBars',
    {
      'sui-w-fit': shape !== 'circle',
      'sui-w-full': shape === 'circle',
      smallerSize: channel?.toUpperCase() !== DESKTOP
    }
  );
  return (
    <div
      className={cx('navBarContainers', { smallerSize: channel?.toUpperCase() !== DESKTOP })}
      data-bugbear-id="navBarContainers"
      style={additionalStyle}
    >
      <div
        className={navBarClasses}
      >
        {data.map((navItem) => {
          const color = getContentColor(navItem.rgb);
          const navBarMaxTileClasses = cx(
            `navBar-${maxTiles}`,
            shape,
            color,
            {
              smallerSize: channel?.toUpperCase() === DESKTOP,
              active: navItem.selected,
              'sui-p-1': navItem.selected,
              'sui-text-primary': color === 'black',
              'sui-text-inverse': color === 'white',
              'sui-justify-center sui-items-center': shape === 'circle'
            },
            'sui-flex sui-aspect-square'
          );
          return (
            <div
              data-testid="color-shades"
              key={navItem.rgb}
              style={{ background: getBackgroundColorFromRgbVal(navItem.rgb) }}
              onClick={() => onNavBarClick(navItem)}
              className={navBarMaxTileClasses}
              data-bugbear-id={(shape) ? `${shape}_navbar` : 'navBarTile'}
            >
              {shape === 'circle' && navItem.selected && <Checkmark />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

NavBar.propTypes = {
  data: arrayOf(shapeProp({})),
  additionalStyle: shapeProp({}),
  onNavBarClick: func.isRequired,
  maxTiles: string.isRequired,
  shape: string,
};

NavBar.defaultProps = {
  data: [],
  shape: '',
  additionalStyle: {}
};

export default NavBar;