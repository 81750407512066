import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Brown Paint',
  introduction: <p>Brown is a versatile color that can be easily incorporated into any room, whether it boasts traditional or contemporary décor. Find out the differences between warm and cool shades of brown paint below along with tips for choosing the right tools for your job.</p>,
  listTitle: 'Shades of Brown Paint',
  listIntroduction: 'If you’re interested in a brown color palette, there are several shades you can choose for your home. Most brown shades fall into two categories: warm or cool.',
  shadesList: [
    {
      title: 'Warm Browns',
      copy: 'Warm brown colors have red and orange undertones. These shades offer a more casual, earthy appearance that create a comfy, welcoming atmosphere. Many shades of brown paint are named after foods and beverages, such as chocolate, espresso, caramel, and coffee. Like their namesakes, these warm brown paint colors offer a similar, rich experience, making them an excellent choice for dining rooms and kitchens.'
    }, {
      title: 'Cool Browns',
      copy: 'Cool brown colors feature gray and blue undertones and offer a more formal look than warm brown colors. Tan is a pale, light brown paint that can be a main color for any room. Taupe is another popular cool brown color that works well as a rich background alongside coral and teal accents'
    }
  ],
  shadesRecommendation: 'Whether you choose a warm or cool shade of brown for your space, consider the lightness or darkness you want. Light and mid-tone shades are more subtle, making them ideal backgrounds for art collections in living rooms, bedrooms, and offices. Dark brown paint colors help anchor a room and work best in smaller spaces or on accent walls. To achieve balance in a room with dark brown walls, make sure to incorporate light-colored ceilings, flooring, and furniture.',
  tipsTitle: 'More Painting Tips',
  tips: [
    <>Before starting your design project, stock up on the <SeoLink href="/b/Paint-Paint-Tools-Supplies/N-5yc1vZci1w">painting tools</SeoLink> you’ll need to get the job done.</>
  ],
  toolsTips: [
    <>
      <BoldText>Brushes:</BoldText>
      {' '}
      Take time to decide which type of
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac">
        paint brush
      </SeoLink>,
      is right for your application. Flat brushes are perfect for large surfaces, while angle
      {' '}
      brushes are recommended for painting trim or cutting in at the ceiling. Thin angle brushes
      {' '}
      are ideal for trimming in corners and edges.
    </>,
    <>
      <BoldText>Rollers:</BoldText>
      {' '}
      If you’re looking for a
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Rollers/N-5yc1vZaqpy">
        paint roller
      </SeoLink>
      , consider the thickness of the nap. The rougher the surface, the thicker the nap you’ll need.
    </>,
    <>
      <BoldText>Tape:</BoldText>
      {' '}
      It’s also important to use
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu">
        painter’s tape
      </SeoLink>
      {' '}
      over masking tape. While masking tape is more affordable than painter’s tape, it leaves a
      {' '}
      sticky residue behind and can cause paint to peel.
    </>
  ],
  closing: 'Whether you’re interested in light brown living room walls or a dark brown accent wall in your bathroom, there are a variety of browns available to help you achieve your desired look.'
};
