import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Green Paint',
  introduction: <p>From subtle nature-inspired tones to lively, energetic bursts of color, green paint offers many design possibilities for your home. Explore a few popular shades and check out our painting tips before starting your next project.</p>,
  listTitle: 'Green Paint Colors',
  listIntroduction: 'Balance is important when choosing a green color scheme. When you choose the right shade of green for your space, it can evoke a sense of renewal and energy. Here are some green paint colors to keep in mind for an upcoming project:',
  shadesList: [
    {
      title: 'Olive Green',
      copy: 'This dark, subdued shade exudes a rich, earthy appearance that can both soften or spice up a room. Olive green color works exceptionally well in kitchens, especially if you have marble countertops or brushed gold or silver hardware. To create a balanced look, pair olive green with off-white and/or black.'
    }, {
      title: 'Sage',
      copy: 'Composed of slate and citron, sage colors are extremely versatile and make an ideal backdrop for cool and warm colors. Sage can also be as bold or as neutral as you’d like, depending on the lightness and saturation. For a livelier feel, choose steel blue and orange to complement sage. If you want to stick with an earthier tone, infuse brown and rust into your sage palette.'
    }, {
      title: 'Mint Green',
      copy: 'This bright, cheerful pastel hue can easily freshen up any room. It works well with a wide variety of colors including coral, lavender, and aqua. Mint green also pairs well with chrome and brass hardware. To maximize mint green’s brightness, be sure to use it in a space where there’s plenty of natural light.'
    }
  ],
  tipsTitle: 'Painting Tools',
  tips: [
    'Before starting your next paint job, be sure you have the appropriate selection of tools on hand.'
  ],
  toolsTips: [
    <>
      <BoldText>
        Brushes:
      </BoldText>
      {' '}
      When searching for a
      {' '}
      <SeoLink
        href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac"
      >
        paint brush
      </SeoLink>
      , check the material of the bristles. Natural bristles are recommended for oil-based paints,
      {' '}
      varnishes, polyurethane, and shellac. Polyester bristles hold their shape and stiffness well,
      {' '}
      making them ideal for latex paints. Nylon/polyester bristles are easy to clean, durable, and
      {' '}
      work well with latex paints.
    </>,
    <>
      <BoldText>Rollers:</BoldText>
      {' '}
      When purchasing
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Rollers/N-5yc1vZaqpy">
        paint rollers
      </SeoLink>
      , check the thickness of the nap. Smoother surfaces require thinner naps while rougher \
      {' '}
      surfaces need thicker ones. Also, don’t forget to get a
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tarps-Drop-Cloths-Plastic-Sheeting-Drop-Cloths/N-5yc1vZci1q">
        drop cloth
      </SeoLink>
      {' '}
      to protect your floors as you use your roller in your home
    </>
  ],
  closing: 'Green paint colors are an inspired choice for the home. With an array of shades to choose from, ranging from cool to warm, you’re sure to find one that complements your style and décor.'
};
