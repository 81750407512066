import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Button } from '@one-thd/sui-atomic-components';
import { Close } from '@one-thd/sui-icons';
import { Image, Overlay } from '@thd-olt-component-react/core-ui';
import { useHeaderObserver } from '@thd-olt-functional/utils';

import { CompareContext, LayoutContext } from '../../../context/Contexts';
import { removeColorFromCompare, removeAllColorsFromCompare } from '../compareActions';
import { getBackgroundColorFromRgbVal } from '../../../utils/utils';
import { CompareSideBySide } from './CompareSideBySide/CompareSideBySide';
import { pushAnalyticsEvent } from '../../../utils/analyticsUtils';

import styles from './CompareFooter.module.scss';

const cx = classNames.bind(styles);
const maxSlidesToShowDesktop = 8;
const maxSlidesToShowMobile = 4;

export const CompareFooter = () => {
  const { compareState, dispatchCompare } = useContext(CompareContext);

  const shouldShowFooter = !!compareState.colorsToCompare.length;
  const { channel } = useContext(LayoutContext);
  let { colorsToCompare } = compareState;
  let colorsCount = colorsToCompare.length;

  const [placeholders, setPlaceholders] = useState([]);
  const [shouldShowOverlay, setShouldShowOverlay] = useState(false);

  useEffect(() => {
    let placeholdersCount;
    let placeholdersLength;
    const tempPlaceholders = [];

    if (channel === 'DESKTOP') {
      placeholdersLength = maxSlidesToShowDesktop - colorsToCompare.length;
    } else {
      colorsToCompare = colorsToCompare.length > maxSlidesToShowMobile
        ? colorsToCompare.slice(0, maxSlidesToShowMobile)
        : colorsToCompare;
      placeholdersLength = maxSlidesToShowMobile - colorsToCompare.length;
    }
    placeholdersCount = Number.isFinite(placeholdersLength) ? placeholdersLength : 1;

    for (let i = 0; i < placeholdersCount; i++) {
      tempPlaceholders.push(i);
    }

    setPlaceholders(tempPlaceholders);
  }, [channel, colorsToCompare]);

  const buildSideBySideAnalyticsData = () => {
    return {
      category: {
        primaryCategory: 'overlay'
      },
      eventInfo: {
        eventName: 'compare color'
      },
      colorExplore: {
        color: colorsToCompare.map((color) => color.colorNumber)
      }
    };
  };

  const openOverlay = () => {
    setShouldShowOverlay(true);
  };

  const closeOverlay = () => {
    setShouldShowOverlay(false);
  };

  const handleViewSideBySideClick = () => {
    pushAnalyticsEvent(buildSideBySideAnalyticsData());
    openOverlay();
  };

  if (!shouldShowFooter && shouldShowOverlay) {
    setShouldShowOverlay(false);
  }

  const [entry] = useHeaderObserver();
  const footerStyle = {
    bottom: channel !== 'DESKTOP' && entry?.visible ? entry.navigation.clientRect?.height : 0
  };

  return (
    <footer className={cx('compareFooter', 'sui-transition-all', 'sui-duration-500')} style={footerStyle}>
      { shouldShowFooter
        && (
          <>
            {!shouldShowOverlay && (
              <div className={cx('colorsStage', 'sui-flex sui-justify-evenly lg:sui-justify-center sui-items-center')}>
                <div className="sui-flex md:sui-items-center max-lg:sui-flex-col max-lg:sui-justify-around">
                  <div className={cx('compareText', 'max-lg:sui-mb-3')}>
                    Select 2 to {channel === 'DESKTOP' ? maxSlidesToShowDesktop : maxSlidesToShowMobile} colors to compare
                  </div>
                  <div className="sui-flex sui-items-around">
                    {colorsToCompare.map((color, index) => {
                      return (
                        <div
                          key={index}
                          className={cx('colorSlide')}
                          style={{
                            backgroundColor: getBackgroundColorFromRgbVal(color.rgb),
                            border: 'none',
                            height: '33px',
                            minWidth: '33px'
                          }}
                        >
                          <div className={cx('closeButton')}>
                            <button
                              type="button"
                              onClick={() => dispatchCompare(removeColorFromCompare(color))}
                              className="sui-bg-primary sui-h-5 sui-w-5 sui-rounded-full sui-border-2 sui-border-solid sui-border-primary"
                            >
                              <Image asset="close-symbol-orange.svg" alt="close" width="30" height="30" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                    {placeholders.map((holder, index) => (
                      <div key={index} className={cx('colorSlide')} />
                    ))}
                  </div>
                </div>
                <div className="sui-flex max-md:sui-flex-col-reverse max-md:sui-justify-around max-md:sui-items-center">
                  <div className="max-md:sui-mt-3 md:sui-mr-3">
                    <Button
                      endIcon={Close}
                      onClick={() => dispatchCompare(removeAllColorsFromCompare())}
                      variant="secondary"
                    >
                      Clear All
                    </Button>
                  </div>
                  <Button
                    data-testid="compare-overlay-button"
                    onClick={handleViewSideBySideClick}
                    variant="primary"
                  >
                    {channel === 'DESKTOP' ? 'View Side-by-Side' : 'Compare'}
                  </Button>
                </div>
              </div>
            )}
            <Overlay
              closeButton
              header={`Compare Colors (${colorsCount})`}
              onClose={closeOverlay}
              open={shouldShowOverlay}
            >
              <CompareSideBySide />
            </Overlay>
          </>
        )}
    </footer>
  );
};