import React, { useContext, useState } from 'react';
import classNames from 'classnames/bind';
import { shape, string } from 'prop-types';

import { Checkbox } from '@one-thd/sui-atomic-components';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Image } from '@thd-olt-component-react/core-ui';

import { removeColorFromCompare } from '../../../compareActions';
import ImageLoader from './ImageLoader/ImageLoader';
import { BuyButtons } from '../../../../BuyButtons/BuyButtons';
import { ColorInfo } from '../../../../ColorInfo/ColorInfo';
import { getContentColor, getBackgroundColorFromRgbVal } from '../../../../../utils/utils';
import { DESKTOP, HOME_DEPOT_STATIC_HOSTNAME } from '../../../../../constants/Constants';
import { pushAnalyticsEvent, buildBuySampleAnalyticsObj } from '../../../../../utils/analyticsUtils';

import { CompareContext, LayoutContext } from '../../../../../context/Contexts';
import styles from './CompareColorCard.module.scss';

const cx = classNames.bind(styles);

export const CompareColorCard = ({ colorInfo, selectedRoomName }) => {
  const { channel } = useContext(LayoutContext);
  const { dispatchCompare } = useContext(CompareContext);
  const [shouldShowRemoveColorOverlay, setShouldShowRemoveColorOverlay] = useState(false);

  const { rgb, colorBrand, colorName, colorNumber } = colorInfo;
  const contentColor = getContentColor(rgb);

  const replaceSpaceWithHyphen = (str) => {
    return str.replace(/ /gi, '-');
  };

  const buildImageSrc = () => {
    const url = `${HOME_DEPOT_STATIC_HOSTNAME}/compare/${channel}/${replaceSpaceWithHyphen(selectedRoomName)}.png?2`;
    return url.toLowerCase();
  };

  const handleBuySampleAnalytics = (itemId) => {
    pushAnalyticsEvent(buildBuySampleAnalyticsObj(itemId, 'compare'));
  };

  const buildAnalyticsDataForCTA = () => {
    return {
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: {
        eventName: 'buy paint'
      },
      eventMode: 'stash',
      stash: {
        colorExplore: {
          selectedColor: colorInfo.colorNumber,
          selectedCategory: selectedRoomName
        }
      }
    };
  };

  const handleBuyPaintAnalytics = () => {
    pushAnalyticsEvent(buildAnalyticsDataForCTA());
  };

  return (
    <div className={cx('colorCardWrapper')}>
      {removeColorFromCompare
        && (
          <div className={cx('closeButton')}>
            <button
              type="button"
              onClick={() => dispatchCompare(removeColorFromCompare(colorInfo))}
              className={cx('colorCardRemoveColor', 'sui-flex sui-justify-center sui-items-center sui-bg-primary')}
            >
              <Image asset="close-symbol-orange.svg" alt="close" height="20" width="20" />
            </button>
          </div>
        )}

      <ImageLoader
        alt={colorName}
        className={cx('imageWrapper')}
        src={buildImageSrc()}
        style={{ background: getBackgroundColorFromRgbVal(rgb) }}
      />
      <div className={cx('cardInfo')} style={{ background: getBackgroundColorFromRgbVal(rgb) }}>
        <ColorInfo
          brandLogoWrapperClassName={cx('brandLogoWrapper')}
          colorBrand={colorBrand}
          colorInfoContainerClassName={cx('colorInfoContainer')}
          colorName={colorName}
          colorNameAndNumberWrapperClassName={cx('colorNameAndNumberWrapper')}
          colorNumber={colorNumber}
          contentColor={contentColor}
          rgb={rgb}
        />

        {channel?.toUpperCase() !== DESKTOP
          && (
            <>
              <div className={cx('removeColorCheckboxWrapper')}>
                <div className="remove-color-checkbox checkbox-btn">
                  <Checkbox
                    checked
                    id={`${colorInfo.colorName}_remove-color-checkbox`}
                    onChange={() => setShouldShowRemoveColorOverlay(true)}
                  />
                </div>
              </div>
              { shouldShowRemoveColorOverlay
                && (
                  <div className={cx('removeColorOverlayWrapper')}>
                    <h4>Remove Color from Compare View?</h4>
                    <div className={cx('buttonsWrapper')}>
                      <button
                        type="button"
                        onClick={() => dispatchCompare(removeColorFromCompare(colorInfo))}
                      >
                        Remove
                      </button>
                      <button type="button" onClick={() => setShouldShowRemoveColorOverlay(false)}>Cancel</button>
                    </div>
                  </div>
                )}
            </>
          )}
      </div>
    </div>
  );
};

CompareColorCard.propTypes = {
  colorInfo: shape({
    rgb: string.isRequired,
    colorBrand: string.isRequired,
    colorName: string.isRequired,
    colorNumber: string.isRequired
  }).isRequired,
  selectedRoomName: string,
};

CompareColorCard.defaultProps = {
  selectedRoomName: 'default room name',
};
