import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'White Paint',
  introduction: <p>Add a refreshing element of purity and sophistication to your bedroom, living room, or kitchen with a beautiful coat of white paint. Before beginning your project, learn about some popular shades of white that can help transform your work or play space.</p>,
  listTitle: 'Shades of White Paint',
  listIntroduction: 'Here are a few shades of white paint that might work for your home:',
  shadesList: [
    {
      title: 'Dove White',
      copy: 'Dove white paint features a touch of gray and offers an ideal balance of cool and warm tones, making it one of the most versatile neutrals available. Dove white is also one of the best white paint colors for large open floor plans, particularly in open-concept kitchens with marble or granite countertops. Use dove white for trim or cabinets to support your main color, or introduce the hue as an elegant, crisp backdrop for art collections.'
    }, {
      title: 'Ivory',
      copy: 'This off-white paint boasts a hint of yellow. It provides a feeling of purity and calmness just like traditional white, but also offers warmth thanks to its yellowish undertones. This makes ivory paint an excellent choice for kitchens, studies, and nurseries. If you’re going for a lighter palette, ivory pairs well with similar colors such as gold or beige. You can also use ivory as an accent color alongside maroon or navy blue for a bolder look.'
    }, {
      title: 'Cream',
      copy: 'Although similar to ivory, cream tends to have more yellow undertones that are silky and rich. Cream, like other white paint colors, is very versatile. In rustic or traditional kitchens with natural wood accents, cream paint helps boost the warmth of the space. For a more striking palette, combine cream with bright greens, reds, or yellows.'
    }
  ],
  tipsTitle: 'Paint Tool Tips',
  tips: [
    <>
      It’s important to have the appropriate tools when you’re working with white paint colors.
    </>
  ],
  toolsTips: [
    <>
      <BoldText>Rollers:</BoldText>
      {' '}
      If you’re shopping for a
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Rollers/N-5yc1vZaqpy">
        paint roller
      </SeoLink>
      , decide whether you need one with a natural or synthetic cover. Natural covers are
      {' '}
      recommended for oil-based paints, while synthetic covers work well with water-based paints.
    </>,
    <>
      <BoldText>Brushes:</BoldText>
      {' '}
      When it comes to
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac">
        paint brush
      </SeoLink>,
      {' '}
      figure out what type you need. Flat brushes are great for painting large surfaces, while
      {' '}
      angled brushes are ideal for painting trim. Thin angle brushes work best for detail work.
    </>,
    <>
      <BoldText>Tapes:</BoldText>
      {' '}
      It's important to have a
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tarps-Drop-Cloths-Plastic-Sheeting-Drop-Cloths/N-5yc1vZci1q">
        drop cloth
      </SeoLink>
      Make sure to use
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu">
        painter’s tape
      </SeoLink>
      {' '}
      for your project instead of masking tape, which tends to leave a sticky residue and peel off
      {' '}
      paint.
    </>
  ],
  closing: 'White paint is a timeless and versatile choice, with dozens of options to choose from. Consider the paint “temperature” -- from cool and crisp to warm and inviting – to find the right one for your décor.'
};
