import React from 'react';
import classNames from 'classnames/bind';
import styles from './ValueMessagingBanner.module.scss';

import {
  HOME_DEPOT_STATIC_HOSTNAME,
  GCP_BRAND_LOGO_PATH
} from '../../constants/Constants';

const cx = classNames.bind(styles);

export const brandLogos = [
  <img className={cx('logo1')} alt="behr logo" key="logo1" src={`${HOME_DEPOT_STATIC_HOSTNAME}${GCP_BRAND_LOGO_PATH}/Behr-black-2023.png`} />,
  <img className={cx('logo2')} alt="ppg logo" key="logo2" src={`${HOME_DEPOT_STATIC_HOSTNAME}${GCP_BRAND_LOGO_PATH}/PPG-logo-colored.png`} />,
  <img className={cx('logo3')} alt="glidden logo" key="logo3" src={`${HOME_DEPOT_STATIC_HOSTNAME}${GCP_BRAND_LOGO_PATH}/Glidden-colored.png`} />,
];
export const bannerMessages = ['NOBODY BEATS OUR PRICES', 'YOUR PERFECT COLOR', 'SATISFACTION'];
