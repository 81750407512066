import React from 'react';
import { string } from 'prop-types';
import { HOME_DEPOT_HOSTNAME, HOME_DEPOT_STATIC_HOSTNAME } from '../../constants/Constants';
import './PromoBanners.scss';

const currentDate = new Date().toISOString(); // Server and client will both use UTC
const gliddenBannerDate = new Date('2024-09-24T09:00:00-04:00').toISOString();
const showUpdatedBanner = new Date(currentDate) >= new Date(gliddenBannerDate);

// Desktop/Tablet image intrinsic size: 700 x 100 px
// Mobile image intrinsic size: 375 x 85 px
export const PromoBanners = ({ channel }) => {
  return (
    <div className="promo-banners sui-justify-center sui-w-full">
      <a href={`${HOME_DEPOT_HOSTNAME}/b/Featured-Products-Paint-Paint-Colors-Behr-2025-Color-Trends/N-5yc1vZ2fkpf9x`} className="promo-banner">
        <img src={`${HOME_DEPOT_STATIC_HOSTNAME}/promo-banner/${channel.toLowerCase()}/2025-Behr-trends-latest.png`} alt="promo1" title="promo1" />
      </a>

      {showUpdatedBanner && (
        <a href={`${HOME_DEPOT_HOSTNAME}/b/Featured-Products-Paint-Paint-Colors-Glidden-2025-Color-Trends/N-5yc1vZ2fkpfgc`} className="promo-banner">
          <img src={`${HOME_DEPOT_STATIC_HOSTNAME}/promo-banner/${channel.toLowerCase()}/2025-glidden-trends.png`} alt="promo2" title="promo2" />
        </a>
      )}
      {!showUpdatedBanner && (
        <a href={`${HOME_DEPOT_HOSTNAME}/b/Featured-Products-Paint-Paint-Colors-Glidden-2024-Color-Trends/N-5yc1vZ2fkpdwj`} className="promo-banner">
          <img src={`${HOME_DEPOT_STATIC_HOSTNAME}/promo-banner/${channel.toLowerCase()}/2024-glidden-trends-updated.png`} alt="promo2" title="promo2" />
        </a>
      )}
    </div>
  );
};

PromoBanners.propTypes = {
  channel: string.isRequired
};
