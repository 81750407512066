import React from 'react';
import SeoLink from '../SeoLink';
import BoldText from '../SeoBoldText';

export default {
  title: 'Yellow Paint',
  introduction: <p>Give your home a splash of sunshine and vibrancy by adding yellow paint to your décor. Consider these shades of yellow to find one that fits your style, plus apply these tips for finding the essential paint tools you’ll need for your DIY paint project.</p>,
  listTitle: 'Shades of Yellow Paint',
  listIntroduction: 'Whether you want yellow accent walls or yellow living room walls, there are multiple shades of yellow paint to help achieve your desired atmosphere. Here are some shades of yellow paint to explore:',
  shadesList: [
    {
      title: 'Gold',
      copy: 'If you’re interested in warm yellow paint colors, gold is a great choice. This hue is often associated with luxury and wealth, but it also lends itself well to a child’s room when paired with colors such as turquoise or coral. If you want gold paint for your walls, experiment with combining other warm colors such as red or rusty orange. Gold also helps brighten up a neutral palette when used as an accent.'
    }, {
      title: 'Buttercream',
      copy: 'Pale yellow paint colors can help enliven and energize a space, and buttercream is no exception. This light yellow paint comes in a variety of tones ranging from warm to bright, so it’s easy to find one that’ll suit your personal taste. Buttercream works well in bathrooms, kitchens, and offices, especially when paired with white or off-white trim and dark wood accents.'
    }, {
      title: 'Mimosa',
      copy: 'This radiant yellow paint creates a welcoming mood in living areas and dining rooms. Mimosa also works well as a vibrant backdrop in a rustic kitchen with white trim and dark furnishings. For complementary colors, you can easily pair mimosa with purple. As an accent, mimosa harmonizes with blue or earthy green palettes.'
    }
  ],
  tipsTitle: 'Paint Tool Tips',
  tips: [
    <>
      Be sure you select the right tools for your paint job if you’re planning to do it by yourself.
    </>
  ],
  toolsTips: [
    <>
      <BoldText>Rollers:</BoldText>
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Rollers/N-5yc1vZaqpy">
        Paint rollers
      </SeoLink>
      {' '}
      are essential to have in your toolbox. It’s important to consider the thickness of the nap.
      {' '}
      Smoother surfaces need thinner naps, while rougher surfaces require thicker naps. Look into
      {' '}
      purchasing
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Buckets-Trays-Paint-Trays/N-5yc1vZaqcd">
        paint trays
      </SeoLink>
      {' '}
      to store your roller when it’s not in use.
    </>,
    <>
      <BoldText>Brushes:</BoldText>
      {' '}
      When it comes to
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Paint-Brushes-Accessories-Paint-Brushes/N-5yc1vZarac">
        paint brush
      </SeoLink>,
      {' '}
      figure out what type you need. Flat brushes are great for painting large surfaces, while
      {' '}
      angled brushes are ideal for painting trim. Thin angle brushes work best for detail work.
    </>,
    <>
      <BoldText>Tapes:</BoldText>
      {' '}
      Make sure you use
      {' '}
      <SeoLink href="/b/Paint-Paint-Tools-Supplies-Tape-Painters-Tape/N-5yc1vZcibu">
        painter’s tape
      </SeoLink>
      {' '}
      instead of masking tape. With painter’s tape, you can keep it on painted areas for up to three
      {' '}
      days. Keep in mind that with masking tape, you’ll risk peeling off a part of your finish if
      {' '}
      left on for three or more days.
    </>
  ],
  closing: 'From warm golden tones to bright, sunny hues, yellow paint offers several decorating options that can breathe new life into your décor.'
};
